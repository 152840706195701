 
 
 
 
 
 /*              LINKED           */



:root {
    --color-blue: #0094ff;
    --color-white: #fff;
    --curve: cubic-bezier(0.42, 0, 0.275, 1.155);
  }
  

  .star {
    position: absolute;
    animation: grow 3s infinite;
    fill: var(--color-blue);
    opacity: 0;
  }
  .star:nth-child(1) {
    width: 12px;
    height: 12px;
    left: 12px;
    top: 16px;
  }
  .star:nth-child(2) {
    width: 18px;
    height: 18px;
    left: 168px;
    top: 84px;
  }
  .star:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 32px;
    top: 162px;
  }
  .star:nth-child(4) {
    width: 20px;
    height: 20px;
    left: 82px;
    top: -12px;
  }
  .star:nth-child(5) {
    width: 14px;
    height: 14px;
    left: 125px;
    top: 162px;
  }
  .star:nth-child(6) {
    width: 10px;
    height: 10px;
    left: 16px;
    top: 16px;
  }
  .star:nth-child(1) {
    animation-delay: 1.5s;
  }
  .star:nth-child(2) {
    animation-delay: 3s;
  }
  .star:nth-child(3) {
    animation-delay: 4.5s;
  }
  .star:nth-child(4) {
    animation-delay: 6s;
  }
  .star:nth-child(5) {
    animation-delay: 7.5s;
  }
  .star:nth-child(6) {
    animation-delay: 9s;
  }
  
  .checkmark {
    position: relative;
    padding: 30px;
    animation: checkmark 5m var(--curve) both;
  }
  .checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: var(--color-white);
  }
  .checkmark__background {
    fill: var(--color-blue);
    animation: rotate 35s linear both infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes grow {
    0%, 100% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes checkmark {
    0%, 100% {
      opacity: 0;
      transform: scale(0);
    }
    10%, 50%, 90% {
      opacity: 1;
      transform: scale(1);
    }
  }

  





  /*              WAITING           */





  .loader {
    --background: linear-gradient(135deg, #5cbbff, #0094ff);
    --shadow: rgba(39, 94, 254, 0.28);
    --text: #6C7486;
    --page: rgba(255, 255, 255, 0.36);
    --page-fold: rgba(255, 255, 255, 0.52);
    --duration: 3s;
    width: 215px;
    height: 140px;
    position: relative;
    border-radius: 0%;
  }
  
  .loader:before, .loader:after {
    --r: -6deg;
    content: "";
    position: absolute;
    bottom: 8px;
    width: 120px;
    top: 80%;
    box-shadow: 0 16px 12px var(--shadow);
    transform: rotate(var(--r));
  }
  
  .loader:before {
    left: 4px;
  }
  
  .loader:after {
    --r: 6deg;
    right: 4px;
  }
  
  .loader div {
    width: 100%;
    height: 100%;
    border-radius: 13px;
    position: relative;
    z-index: 1;
    perspective: 600px;
    box-shadow: 0 4px 6px var(--shadow);
    background-image: var(--background);
  }
  
  .loader div ul {
    margin: 0;
    padding: 0;
    position: relative;
  }
  
  .loader div ul li {
    --r: 180deg;
    --o: 0;
    --c: var(--page);
    position: absolute;
    top: 10px;
    left: 10px;
    transform-origin: 100% 50%;
    color: var(--c);
    opacity: var(--o);
    transform: rotateY(var(--r));
    -webkit-animation: var(--duration) ease infinite;
    animation: var(--duration) ease infinite;
  }
  
  .loader div ul li:nth-child(2) {
    --c: var(--page-fold);
    -webkit-animation-name: page-2;
    animation-name: page-2;
  }
  
  .loader div ul li:nth-child(3) {
    --c: var(--page-fold);
    -webkit-animation-name: page-3;
    animation-name: page-3;
  }
  
  .loader div ul li:nth-child(4) {
    --c: var(--page-fold);
    -webkit-animation-name: page-4;
    animation-name: page-4;
  }
  
  .loader div ul li:nth-child(5) {
    --c: var(--page-fold);
    -webkit-animation-name: page-5;
    animation-name: page-5;
  }
  
  .loader div ul li svg {
    width: 90px;
    height: 120px;
    display: block;
  }
  
  .loader div ul li:first-child {
    --r: 0deg;
    --o: 1;
  }
  
  .loader div ul li:last-child {
    --o: 1;
  }
  
  .loader span {
    display: block;
    left: 0;
    right: 0;
    top: 100%;
    margin-top: 20px;
    text-align: center;
    color: var(--text);
  }
  
  @keyframes page-2 {
    0% {
      transform: rotateY(180deg);
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    35%, 100% {
      opacity: 0;
    }
  
    50%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-3 {
    15% {
      transform: rotateY(180deg);
      opacity: 0;
    }
  
    35% {
      opacity: 1;
    }
  
    50%, 100% {
      opacity: 0;
    }
  
    65%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-4 {
    30% {
      transform: rotateY(180deg);
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    65%, 100% {
      opacity: 0;
    }
  
    80%, 100% {
      transform: rotateY(0deg);
    }
  }
  
  @keyframes page-5 {
    45% {
      transform: rotateY(180deg);
      opacity: 0;
    }
  
    65% {
      opacity: 1;
    }
  
    80%, 100% {
      opacity: 0;
    }
  
    95%, 100% {
      transform: rotateY(0deg);
    }
  }
  





/*              MISMATCH                */





.Container {
  text-align: center;
  position: relative;
}

.MainGraphic {
  position: relative;
}

.Cog {
  width: 10rem;
  height: 10rem;
  fill: #0094ff;
  transition: easeInOutQuint();
  -webkit-animation: CogAnimation 5s infinite;
          animation: CogAnimation 5s infinite;
}
.Spanner {
  position: absolute;
  transform: rotate(20deg);
  top: 10%;
  left: 20%;
  width: 10rem;
  height: 10rem;
  fill: #8EC0E4;
  -webkit-animation: SpannerAnimation 4s infinite;
          animation: SpannerAnimation 4s infinite;
}
.Hummingbird {
  position: absolute;

  fill: #0094ff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@-webkit-keyframes CogAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes CogAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes SpannerAnimation {
  0% {
    transform: translate3d(20px, 20px, 1px) rotate(0deg);
  }
  10% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  15% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  20% {
    transform: translate3d(1000px, -1000px, 1px) rotate(180deg);
  }
  30% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  40% {
    transform: translate3d(1000px, -1000px, 1px) rotate(360deg);
  }
  50% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  100% {
    transform: translate3d(0, 0px, 0px) rotate(360deg);
  }
}
@keyframes SpannerAnimation {
  0% {
    transform: translate3d(20px, 20px, 1px) rotate(0deg);
  }
  10% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  15% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  20% {
    transform: translate3d(1000px, -1000px, 1px) rotate(180deg);
  }
  30% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(360deg);
  }
  40% {
    transform: translate3d(1000px, -1000px, 1px) rotate(360deg);
  }
  50% {
    transform: translate3d(-1000px, 1000px, 1px) rotate(180deg);
  }
  100% {
    transform: translate3d(0, 0px, 0px) rotate(360deg);
  }
}


.opac-0 {
  opacity: 0;
}

.opac-1 {
  opacity: 1;
}