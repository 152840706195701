/* Container for the wave animation */
.wave-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Common style for all waves */
.wave {
  position: absolute;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 1);
  width: 100%;
  height: 100%;
  opacity: 1;
  animation: wave 4s ease-out infinite;
}

/* Individual timing for each wave */
.wave1 {
  animation-delay: 0s;
}

.wave2 {
  animation-delay: 1s;
}

.wave3 {
  animation-delay: 2s;
}

/* Keyframes to create the wave effect */
@keyframes wave {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}

.containerRing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-ring-container {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-ring {
  transform: rotate(-90deg); /* Start the circle from the top */
}

.progress-ring__circle {
  stroke-dasharray: 408; /* Circumference of the circle */
  stroke-dashoffset: 408; /* Hide the circle initially */
  transition: stroke-dashoffset 0.4s ease;
}

.progress-ring__background {
  stroke-dasharray: 408;
  stroke-dashoffset: 0;
}

.progress-ring-container span {
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  user-select: none;
  pointer-events: none; /* Avoid interaction */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Start slightly above */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End at original position */
  }
}

.animate-fadeIn {
  animation: fadeIn 0.7s ease forwards; /* Apply fadeIn animation */
}
