.performance{
    background-color: #D8E5F9;
    border-radius: 10px;
    color: black;
    font-weight: 500;
    padding: 2px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
}
.performance:hover{
    transform: scale(.95);
    transition-duration: 200ms;
}

/* Media Query */
@media (max-width: 1024px) {
    .min-width{
        width: 100%;
    }
}