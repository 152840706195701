.navbar-head {
  background-color: #352d26b5;
}

.navbar-head div {
  text-align: center;
  padding: 0.5rem;
  color: white;
}

.navbar-head div a {
  text-align: center;
  padding: 0.3rem 0rem;
  color: white;
}

.navbar-head div a:hover {
  border: 1px solid;
  border-radius: 0.3rem;
}

.login:hover {
  background-color: grey;
  color: white;
  text-decoration: none;
}

.navbar-brand-logo {
  height: 3rem;
}

.navbar-nav li {
  padding: 0 1.5rem;
}

.navbar-links {
  font-size: 1rem;
  font-weight: 500;
  color: black;
  position: relative;
}

.navbar-links:hover {
  text-decoration: overline;
}

.navbar-style {
  background-color: white;
  width: 100vw;
  z-index: 5000;
}

.subnavbarmenu {
  position: fixed;
  z-index: 1000;
  background-color: #ededed;
  top: 6%;
  right: 8%;
  border-radius: 0.2rem;
  box-shadow: -4px 4px 8px 0 #0000007a;
  /* width: 27vw; */
}

.nav-btn-style {
  overflow: hidden;
  padding: 0px 10px;
  border-radius: 5rem;
  color: black;
  margin: 1rem 0rem;
}

.nav-btn-style:hover {
  color: #fff;
  box-shadow: 0px 4px 4px 0px black;
}

.nav-btn-icon-style {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  color: #fff;
  padding: 1px;
  border-radius: 62px;
  position: relative;
  right: -9px;
  /* height: 41px; */
}

.font2-style {
  font-size: 14px;
}

.navbar2 .position-style {
  width: -webkit-fill-available;
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .navbar2 {
    display: none;
  }
  .promise2 {
    display: none;
  }

  .navbar-brand-logo {
    height: 2rem;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 991px) {
  .navbar2 {
    display: none;
  }
  .promise1 {
    display: none;
  }

  .navbar-brand-logo {
    height: 3rem;
  }
}

/* Media queries for large screens (between 769px and 992px) */
@media (min-width: 992px) {
  .navbar1 {
    display: none;
  }
  .promise1 {
    display: none;
  }
}

.kyc-text {
  margin: 0;
  padding: 5px 0px;
  font-family: math;
  font-weight: bold;
  text-align: center;
  background-color: rgb(190 215 255);
  color: #000;
}

.main-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 57px 80px;
}

@media (max-width: 991px) {
  .main-container {
    padding: 0 20px;
  }
}

.header {
  align-self: stretch;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  color: #000;
  text-align: center;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.navigation-menu {
  display: flex;
  gap: 20px;
  font-weight: 500;
  margin: auto 0;
}

@media (max-width: 991px) {
  .navigation-menu {
    flex-wrap: wrap;
  }
}

.menu-item {
  font-family: Karla, sans-serif;
}

.search-form {
  background-color: #fff;
  display: flex;
  padding-left: 28px;
  gap: 20px;
  font-weight: 300;
  justify-content: space-between;
  border-radius: 50px;
}

.search-input {
  font-family: Karla, sans-serif;
  margin: auto 0;
}

.search-icon {
  aspect-ratio: 1;
  object-fit: auto;
  width: 35px;
  fill: #5ab2ff;
}

.action-button {
  font-family: Karla, sans-serif;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  background-color: #5ab2ff;
  font-weight: 500;
  padding: 8px 21px;
}

@media (max-width: 991px) {
  .action-button {
    padding: 0 20px;
  }
}

.header-icons {
  align-self: stretch;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin: auto 0;
}

.main-logo {
  aspect-ratio: 1;
  object-fit: auto;
  width: 55px;
}

.secondary-logo {
  aspect-ratio: 25;
  object-fit: auto;
  width: 56px;
  stroke-width: 2px;
  stroke: #4bb4ff;
  border: 2px solid rgba(75, 180, 255, 1);
  margin-top: 7px;
}

.main-section {
  display: flex;
  margin-top: 50px;
  width: 100%;
  max-width: 1305px;
  align-items: start;
  gap: 20px;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .main-section {
    max-width: 100%;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 34%;
}

@media (max-width: 991px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar-item {
  display: flex;
  flex-direction: column;
  color: #212121;
}

.title-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.item-icon {
  aspect-ratio: 1;
  object-fit: auto;
  width: 38px;
}

.item-title {
  font-family: Karla, sans-serif;
  margin: auto 0;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 1.92px;
}

.vertical-line {
  border: 3px solid rgba(0, 0, 0, 0.3);
  height: 98px;
}

.item-details {
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 24px;
  font-weight: 400;
  margin-top: 12px;
  letter-spacing: 0.48px;
}

.detail-item {
  font-family: Karla, sans-serif;
}

.item-content {
  border: 2px solid;
  display: flex;
  flex-direction: column;
}

.bg-black {
  background-color: #000;
  color: #fff;
}

.bg-lightblue {
  background-color: #dbedf9;
  color: #000;
}

.item-top-section {
  height: 2px;
}

.item-bottom-section {
  display: flex;
  margin-top: 10px;
  gap: 15px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.65px;
  line-height: 134%;
}

.text-white {
  fill: #fff;
}

.text-black {
  fill: #000;
}

.item-footer {
  display: flex;
  margin-top: 12px;
  gap: 10px;
}

.footer-line {
  width: 100%;
  height: 2px;
}

.footer-dot {
  width: 10px;
  height: 2px;
}

.main-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 66%;
}

@media (max-width: 991px) {
  .main-content {
    width: 100%;
  }
}

.footer {
  display: flex;
  gap: 20px;
  font-size: 24px;
  color: rgba(33, 33, 33, 0.9);
  font-weight: 400;
  letter-spacing: 0.48px;
}

.footer-link {
  font-family: Karla, sans-serif;
  text-decoration: underline;
}
