@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap");
* {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

:root {
  --primary-color: #5ab2ff;
  --secondary-color: #dbedf9;
  --text: #212121;
  --white: #fff;
  --black: #000;
  --red: #c20202;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.hiddenMenu,
.hidden-a {
  display: none;
}

.navbar3 {
  width: 90%;
  height: 75px;
  /* padding: 0 110px; */
  background-color: transparent;
  gap: 1rem;
}

.logo {
  /* margin: 1rem 0; */
  position: relative;
  right: 12px;
  margin-right: 1rem;
}

.nav-logo {
  height: 55px;
  width: 55px;
  padding: 2rem;
}

.accordOpen {
  height: 100px;
  opacity: 1;
  transition: all 0.5s;
}

.accordClose {
  height: 0px;
  opacity: 0;
  transition: all 0.5s;
}

.showPage{
  display: flex;
}

.nav-centerbar {
  width: 85%;
  height: 45px;
  background-color: var(--secondary-color);
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 400;
  color: var(--black);
  padding: 0 0.5rem 0 2rem;
}

.nav-centerbar-left a {
  color: var(--black);
  padding-right: 1.5rem;
  /* transition: all ease-in-out; */
  transition: all 0.2s linear;
}

.active,
.nav-centerbar-left a:hover {
  /* background-color: #d8e5f9;
  border-radius: 50%; */
  text-decoration: underline var(--primary-color);
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
  text-underline-offset: 4px;

  /* color: var(--primary-color); */
}

.next-month,
.previous-month {
  color: #d7d7d7;
}

.nav-centerbar-right {
  position: relative;
  box-sizing: border-box;
  width: fit-content;
}

.search_input {
  position: relative;
  box-sizing: border-box;
  height: 33px;
  width: 381px;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  border-radius: 50px;
  outline: none;
  /* padding-bottom: 4px; */
  padding-left: 20px;
  font-size: 0.9em;
  color: var(--black);
  background-color: var(--white);
  transition: all 0.3s ease;
}

.search_input::placeholder {
  color: var(--black);
}

.iconContainer {
  position: absolute;
  right: 0;
  height: 33px;
  width: 33px;
  box-sizing: border-box;
  padding-top: 5px;
  border-radius: 50%;
  border: none;
  transition: all 0.3s ease;
  background-color: var(--primary-color);
  padding-bottom: 5px;
}

.search_icon {
  box-sizing: border-box;
  fill: var(--black);
  font-size: 1rem;
  text-align: center;
}

.user-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.hamburger {
  transform: 0.2s;
  position: relative;
}
.hamburger .checkbox {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.line {
  transition: 0.5s;
  stroke-width: 6px;
  stroke: black;
}
.lineTop {
  stroke-dasharray: 40 40;
  stroke-dashoffset: 25;
}
.lineBottom {
  stroke-dasharray: 40 40;
  stroke-dashoffset: 60;
}
.lineMid {
  stroke-dasharray: 40 40;
}
.hamburger .checkbox:checked + svg .line {
  stroke: var(--red);
}
.hamburger .checkbox:checked + svg .lineTop {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(45deg) translate(-7px, -5px);
}
.hamburger .checkbox:checked + svg .lineMid {
  stroke-dashoffset: 40;
}
.hamburger .checkbox:checked + svg .lineBottom {
  stroke-dashoffset: 0;
  transform-origin: left;
  transform: rotateZ(-45deg) translate(-5px, 5px);
}

/* Sidebar */

.sidebar3 {
  position: absolute;
  /* height: max-content; */
  width: 83%;
  background-color: var(--secondary-color);
  padding: 2rem;
  border-radius: 30px 30px 30px 30px;
  transition: all 0.4s ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sidebar-main {
  width: 100%;
  height: 100%;
  padding: 0 2rem;
}

.top-side {
  height: 100px;
  width: 100%;
  padding: 0rem 4rem;
}

.left-section {
  width:25%;
}

.right-section {
  height: 100%;
  width: 75%;
  /* padding-top: 0.5rem; */
  /* margin-left: 8rem; */
}

.crosslogo {
  height: 35px;
  width: 35px;
}

.crossicon {
  transform: 0.2s;
  position: relative;
}
.crossicon .checkbox {
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.paste-button {
  position: relative;
  display: block;
  width: 243px;
  overflow: hidden;
}

.button2 {
  background-color: var(--secondary-color);
  color: var(--text);
  padding: 10px 15px;
  font-size: 0.95rem;
  font-weight: 600;
  /* border: 2px solid transparent; */
  border-radius: 15px;
  cursor: pointer;
}

.statement-icon {
  margin-right: 5px;
  width: 23px;
}

/* .dropdown-content {
  margin-left: 50px;
  min-width: 200px;
  width: 100%;
} */

.border-style-try {
  
  border-left: 4px solid rgb(99, 99, 99);
  transition: height 1s ease-in-out;
}

.dropdown-content a {
  color: var(--text);
  width: 180px; 
  font-size: 0.9rem;
  padding: 8px 0px 8px 20px;
  font-weight: 600;
  text-decoration: none;
  transition: 0.5s;
}

.dropdown-content a:hover {
  background-color: var(--white);
  color: var(--text);
}

.dropdown-content a:focus {
  background-color: var(--primary-color);
  color: var(--secondary-color);
}

/* .left-bottom {
  margin-left: 32px;
} */

.left-bottom a {
  font-size: 0.9rem;
  text-decoration: none;
  color: var(--text);
  font-weight: 500;
  /* padding-right: 1.3rem; */
}

.paste-button:hover button {
  border-radius: 15px 15px 0px 0px;
}

.sidebar-icon-drop {
  padding-left: 3rem;
}
.sidebar-icon-drop2 {
  padding-left: 4.3rem;
}

.sidebar-mini-icon-drop {
  margin-right: 5px;
}

.fancy {
  background-color: transparent;
  border: 2px solid var(--black);
  width: 250px;
  border-radius: 0;
  box-sizing: border-box;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 12px;
  /* margin-left: 3rem; */
  /* margin-top: 1rem; */
}

.fancy .text {
  font-size: 1.05em;
  line-height: 1em;
  /* padding-left: 2em; */
  /* display: block; */
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--black);
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: var(--secondary-color);
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: var(--secondary-color);
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: var(--secondary-color);
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: var(--white);
  background: black;
}

.fancy:hover::before {
  width: 0.9375rem;
  background: var(--white);
}

.fancy:hover .text {
  color: var(--white);
  padding-left: -1.5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}

.right-sec-icon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.right-sec-icon:hover {
  color: var(--black);
}

.sec-icon {
  stroke: var(--black);
  color: var(--white);
  transition: 0.5s ease-out, left 0.3s ease-out;
}

.fancy:hover .sec-icon {
  stroke: var(--white);
  color: var(--black);
}
.sec-icon1 {
  transition: 0.5s ease-out, left 0.3s ease-out;
}

.fancy:hover .sec-icon1 {
  stroke: #254194;
  fill: var(--white);
}

@media screen and (max-width: 1440px) {
  .navbar3 {
    /* padding: 0 60px; */
    height: 75px;
  }

  .nav-centerbar {
    height: 45px;
    font-size: 0.95rem;
  }

  .search_input {
    height: 30px;
    width: 320px;
    padding-left: 20px;
    font-size: 0.9rem;
  }

  .nav-centerbar-left a {
    padding-right: 1rem;
    font-weight: 500;
  }

  .iconContainer {
    height: 30px;
    width: 30px;
  }

  .search_icon {
    font-size: 0.8rem;
  }

  .user-icon {
    height: 37px;
    width: 37px;
  }

  .hamburger {
    height: 37px;
    width: 37px;
  }

  /* sidebar  */

  .sidebar3 {
    width: 82%;
    height: max-content;
    min-height: 300px;
    padding-top: 1rem;
    margin: 0 !important;
  }

  .sidebar-main {
    padding: 1rem 1rem 1.5rem 1rem;
  }

  .top-side {
    padding: 0rem 2rem;
  }

  .right-section {
    /* padding-top: 0.5rem; */
    /* margin-left: 2rem; */
  }

  .crosslogo {
    height: 30px;
    width: 30px;
  }

  .paste-button {
    width: 300px;
  }

  .button2 {
    background-color: var(--secondary-color);
    color: var(--text);
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
  }

  .statement-icon {
    margin-right: 5px;
    width: 25px;
  }

  .dropdown-content {
    /* margin-left: 30px; */
    min-width: 200px;
    width: 100%;
  }

  .dropdown-content a {
    font-size: 0.9rem;
    padding: 4px 10px;
    margin-right: 2rem;
  }

  /* .left-bottom {
    margin-left: 16px;
  } */

  .left-bottom a {
    font-size: 0.9rem;
    /* padding-right: 1rem; */
  }

  .fancy {
    width: 200px;
    font-size: 0.7rem;
  }

  .right-sec-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 1024px) {
  .navbar3 {
    /* padding: 0 40px; */
    height: 75px;
  }

  .nav-centerbar {
    height: 40px;
    font-size: 0.9rem;
    width: 90%;
  }

  .accordOpen {
    height: 70px;
    opacity: 1;
    transition: all 0.5s;
  }

  .accordClose {
    height: 0px;
    opacity: 0;
    transition: all 0.5s;
  }

  .search_input {
    height: 30px;
    width: 260px;
    padding-left: 20px;
    font-size: 0.8rem;
  }

  .nav-centerbar-left a {
    padding-right: 1rem;
    font-weight: 500;
  }

  .iconContainer {
    height: 30px;
    width: 30px;
  }

  .search_icon {
    font-size: 0.9rem;
  }

  .user-icon {
    height: 32px;
    width: 32px;
  }

  .hamburger {
    height: 28px;
    width: 28px;
  }

  .sidebar3 {
    width: 81%;
    min-height: 250px;
  }

  .sidebar-main {
    padding: 0 1rem;
  }

  .top-side {
    padding: 0rem 2rem;
  }

  .right-section {
    /* padding-top: 0.5rem; */
    /* margin-left: 4rem; */
  }

  .crosslogo {
    height: 30px;
    width: 30px;
  }

  .paste-button {
    width: 300px;
  }

  .button2 {
    background-color: var(--secondary-color);
    color: var(--text);
    padding: 5px 10px;
    font-size: 1rem;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
  }

  .statement-icon {
    margin-right: 5px;
    width: 22px;
  }

  .nav-centerbar-right {
    position: relative;
  }

  .search_input::placeholder {
    color: var(--black);
  }

  .dropdown-content {
    /* margin-left: 30px; */
    min-width: 200px;
    width: 100%;
  }

  .dropdown-content a {
    font-size: 0.9rem;
    padding: 4px 10px;
    margin-right: 2rem;
  }

  /* .left-bottom {
    margin-left: 16px;
  } */

  .left-bottom a {
    font-size: 1rem;
    /* padding-right: 1rem; */
  }

  .fancy {
    width: 160px;
    padding: 1em 1em;
    font-size: 10px;
    margin-left: 1rem;
    /* margin-top: 1.5rem; */
  }

  .right-sec-icon {
    /* margin-right: 10px; */
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 900px) {
  .navbar3 {
    /* padding: 0 30px; */
    gap: 1.5rem;
  }

  .search_input {
    height: 25px;
    width: 200px;
    padding-left: 20px;
    font-size: 0.9rem;
  }

  .nav-centerbar-left a {
    padding-right: 0.8rem;
    font-size: 0.8rem;
  }

  .iconContainer {
    height: 25px;
    width: 25px;
  }

  .search_icon {
    font-size: 0.7rem;
  }

  .user-icon {
    height: 30px;
    width: 30px;
  }

  .hamburgerIcon {
    height: 30px;
    width: 30px;
  }

  .nav-centerbar {
    width: 90%;
  }

  .accordOpen {
    height: 70px;
    opacity: 1;
    transition: all 0.5s;
  }

  .accordClose {
    height: 0px;
    opacity: 0;
    transition: all 0.5s;
  }

  .sidebar3 {
    width: 81%;
    height: max-content;
    padding: 1rem;
    border-radius: 1rem;
  }

  .sidebar-main {
    padding: 0 0rem;
  }

  .top-side {
    padding: 0rem 2rem;
  }

  .right-section {
    /* padding-top: 0.5rem; */
    /* margin-left: 3rem; */
  }

  .crosslogo {
    height: 30px;
    width: 30px;
  }

  .paste-button {
    width: 200px;
  }

  

  .button2 {
    background-color: var(--secondary-color);
    color: var(--text);
    padding: 5px 10px;
    font-size: 0.9rem;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
  }

  .statement-icon {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  .nav-centerbar-right {
    position: relative;
  }

  .search_input::placeholder {
    color: var(--black);
  }

  .dropdown-content {
    /* margin-left: 30px; */
    min-width: 200px;
    width: 100%;
  }

  .dropdown-content a {
    font-size: 0.8rem;
    padding: 2px 5px;
    margin-right: 1rem;
  }
/* 
  .left-bottom {
    margin-left: 16px;
  } */

  .left-bottom a {
    font-size: 0.8rem;
    /* padding-right: 1rem; */
  }

  .fancy {
    width: 140px;
    padding: 1em 1em;
    font-size: 8px;
    margin-left: 0.8rem;
    /* margin-top: 1rem; */
  }

  .right-sec-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 500px) {
  .navbar3 {
    /* padding: 0 20px; */
    gap: 1rem;
  }

  .nav-centerbar {
    width: 75%;
    height: 35px;
    background-color: var(--secondary-color);
    border-radius: 25px;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--black);
    padding: 5px !important;
  }

  .nav-centerbar-left {
    display: none;
    width: 0% !important;
  }

  .nav-centerbar-right {
    width: 100%;
  }

  .search_input {
    height: 25px;
    width: 100% !important;
    font-size: 0.9rem;
  }

  .iconContainer {
    height: 25px;
    width: 25px;
  }

  .search_icon {
    font-size: 0.7rem;
  }

  .user-icon {
    height: 30px;
    width: 30px;
  }

  .hamburgerIcon {
    height: 30px;
    width: 30px;
  }

  .nav-centerbar {
    width: 90%;
  }

  .hiddenPage {
    display: none;
  }

  .hiddenMenu {
    display: block;
  }

  .top-section {
    padding-bottom: 1rem;
  }

  .top-section a {
    color: var(--black);
    padding-right: 1.5rem;
    transition: all 0.2s linear;
  }

  .active,
  .top-section a:hover {
    text-decoration: underline var(--primary-color);
    text-decoration-thickness: 2px;
    text-decoration-skip-ink: none;
    text-underline-offset: 4px;
  }

  .sidebar3 {
    width: 95%;
    height: max-content;
    min-height: 400px;
    padding: 1rem;
    border-radius: 1rem;
    padding-bottom: 2rem;
    overflow: hidden;
  }

  .sidebar-mini-icon-drop{
    margin-left: 0;
    padding-left: 0;
  }

  .sidebar-main {
    padding: 0 0rem;
  }

  .top-side {
    padding: 0rem 2rem;
  }

  .right-section {
    /* padding-top: 0.5rem; */
    width: 100%;
  }

  .crosslogo {
    height: 30px;
    width: 30px;
  }

  .paste-button {
    width: 150px;
    align-self: center;
  }

  .button2 {
    background-color: var(--secondary-color);
    color: var(--text);
    padding: 5px 10px;
    font-size: 0.8rem;
    font-weight: 600;
    border: 2px solid transparent;
    border-radius: 15px;
    cursor: pointer;
  }

  .statement-icon {
    margin-right: 5px;
    width: 18px;
    height: 18px;
  }

  .nav-centerbar-right {
    position: relative;
  }

  .search_input::placeholder {
    color: var(--black);
  }

  .dropdown-content {
    /* margin-left: 20px; */
    min-width: 100px;
    width: 100%;
  }

  .dropdown-content a {
    font-size: 0.8rem;
    padding: 2px 5px;
    margin-right: 1rem;
  }

  .hidden-a {
    display: inline-block;
    padding-top: 1rem;
    /* padding-right: 5rem; */
  }

  .bottom-sec a {
    font-size: 0.9rem;
    padding-right: 1rem;
    /* padding-top: 1rem; */
  }

  .fancy {
    width: 130px;
    padding: 1em 1em;
    font-size: 8px;
    margin-left: 0rem;
    /* margin-top: 1rem; */
  }

  .right-sec-icon {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }

  .right-section {
    width: 100%;
    /* margin-left: 0; */
  }

  .right-top {  
    width: 100%;
    /* column-gap: 2rem; */
     }

  .left-section {
    width: 100%;
    display: flex;
  }

  .bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .top-section {
    margin-top: 1rem;
    padding-bottom: 0.7rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .top-section a {
    font-size: 0.7rem;
    font-weight: 600;
    color: var(--black);
    padding-right: 0.8rem;
    transition: all 0.2s linear;
  }

  .sidebar-icon-drop {
    padding-left: 2.5rem !important;
  }
  .sidebar-icon-drop2 {
    padding-left: 2.5rem !important;
  }

  .accordOpen {
    height: 70px;
    opacity: 1;
    transition: all 0.5s;
  }

  .accordClose {
    height: 0px;
    opacity: 0;
    transition: all 0.5s;
  }

  .sidebar3 {
    height: max-content;
    min-height: 400px;
  }
}

.circle {
  width: 40px;
  height: 40px;
  background: transparent;
  border: 4px solid #e91e63;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin: 10px 20px;
}

.circle:after {
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.circle:before {
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.circle[data-animation="showShadow"] {
  -moz-box-shadow: 0px 0px 0px 0px #e91e63 inset;
  -webkit-box-shadow: 0px 0px 0px 0px #e91e63 inset;
  box-shadow: 0px 0px 0px 0px #e91e63 inset;
  -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  border: 0px solid white;
}

.circle[data-animation="showShadow"]:before,
.circle[data-animation="showShadow"]:after {
  -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}

.circle[data-animation="showShadow"]:not(.showShadow):hover {
  -moz-box-shadow: 0px 0px 0px 6px #e91e63 inset;
  -webkit-box-shadow: 0px 0px 0px 6px #e91e63 inset;
  box-shadow: 0px 0px 0px 6px #e91e63 inset;
}

.circle[data-animation="showShadow"]:not(.showShadow):hover:before {
  -moz-transform: scale(0.7) rotate(45deg);
  -ms-transform: scale(0.7) rotate(45deg);
  -webkit-transform: scale(0.7) rotate(45deg);
  transform: scale(0.7) rotate(45deg);
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.circle[data-animation="showShadow"]:not(.showShadow):hover:after {
  -moz-transform: scale(0.7) rotate(-45deg);
  -ms-transform: scale(0.7) rotate(-45deg);
  -webkit-transform: scale(0.7) rotate(-45deg);
  transform: scale(0.7) rotate(-45deg);
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.circle.showShadow {
  -moz-transition: ease-out 300ms;
  -o-transition: ease-out 300ms;
  -webkit-transition: ease-out 300ms;
  transition: ease-out 300ms;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}