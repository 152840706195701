.banner-head {
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
  color: #8d5c00;
}
.banner-main {
  font-size: 10rem;
  font-family: sans-serif;
  font-weight: bolder;
  text-transform: uppercase;
  line-height: 9rem;
  background: -webkit-linear-gradient(#333, #9fa6b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-main2 {
  font-size: 5rem;
  font-weight: bolder;
  line-height: 5rem;
  background: -webkit-linear-gradient(#333, #9fa6b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.custodian-sideBtn-head {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 5px;
}
.ribbon {
  z-index: 1;
  box-shadow: 1px 1px 6px 1px black;
  background-color: #e4a11b;
  position: relative;
  top: 5px;
  left: -5px;
}

.shadow-style {
  width: 0;
  height: 0;
  border-top: 39px solid transparent;
  border-left: 50px solid #ffffff;
  border-bottom: 40px solid transparent;
  box-shadow: 10px 0px 6px 1px black;
  background-color: #e4a11b;
}

.shadow-style2 {
  z-index: 0;
  position: relative;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 39px solid transparent;
  border-right: 50px solid #ffffff;
  border-bottom: 40px solid transparent;
  box-shadow: -10px 0px 6px 1px black;
  background-color: #e4a11b;
}

.shadow-style3 {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 30px solid #ffffff;
  border-bottom: 20px solid transparent;
  box-shadow: 10px 0px 6px 1px black;
  background-color: #e4a11b;
}

.shadow-style4 {
  z-index: 0;
  position: relative;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 30px solid #ffffff;
  border-bottom: 20px solid transparent;
  box-shadow: -10px 0px 6px 1px black;
  background-color: #e4a11b;
}

@media (max-width: 1400px) {
  .custodian-sideBtn-head {
    font-size: 1.3rem;
  }

  .banner-head {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .banner-main {
    font-size: 9rem;
    line-height: 6rem;
  }
  .banner-main2 {
    font-size: 2.8rem;
    font-weight: bolder;
    line-height: 2.5rem;
    color: #ffae0d;
  }
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .text2-style {
    font-size: 0.9rem;
  }

  .custodian-sideBtn-head {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .banner-head {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 2rem;
    color: #8d5c00;
  }
  .banner-main {
    font-size: 6rem;
    font-family: sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    line-height: 5rem;
    color: #ffae0d;
  }
  .banner-main2 {
    font-size: 2.4rem;
    font-weight: bolder;
    line-height: 2.5rem;
    color: #ffae0d;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1000px) {
  .text2-style {
    font-size: 1.1rem;
  }
  .custodian-sideBtn-head {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .banner-head {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    color: #8d5c00;
  }
  .banner-main {
    font-size: 7.5rem;
    font-family: sans-serif;
    font-weight: bolder;
    text-transform: uppercase;
    line-height: 6.5rem;
    color: #ffae0d;
  }
  .banner-main2 {
    font-size: 3.4rem;
    font-weight: bolder;
    line-height: 3.4rem;
    color: #ffae0d;
  }
}

.Residex-row-style:hover{
  border-color: #009688;
}

/* Popup animation */
.popup-container {
  height: 100vh;
  position: fixed;
  background: #0a0a0a38;
  width: 100vw;
  top: 0;
  left: 0px;
  z-index: 4000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes swipe-down {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

/* Feedback Animation */
.animated-slide-downfeedback {
  animation: slide-down1 0.2s ease-in-out;
}

@keyframes slide-down1 {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}