.selected4 {
  background-color: #cbdcf7bd;
  padding: 3px 0 3px 60px;
  cursor: pointer;
  color: #0051d1;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
}

.kyc-btn-style2 {
  padding: 3px 0 3px 60px;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
}
/* .container-primary {
  min-height: 96vh;
} */

.SearchList_Container2 {
  padding: 0px;
  font-size: 0.8rem;
  position: absolute;
  background-color: #fff;
  width: 305px;
  max-height: 70vh;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
}

.bid-verify {
  width: 33rem;
}

@media (max-width: 1024px) {
  .hidden-768 {
    display: none;
  }
  .icon-size{
    font-size: 24px;
  }
}
@media (min-width: 1024px) {
  .icon-size{
    font-size: 18px;
  }
  .gap-style{
    gap: 40px;
  }
}

.nav-active{
  position: relative;
  top: -25px;
  transition: 0.4s all;
}

.nav-active span {
  font-size: 21px;
}


/* .block-768 {
  display: block;
} */