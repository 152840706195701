.link-style {
  background: white;
  cursor: pointer;
}

.link-style:hover {
  background: grey;
  cursor: pointer;
}

.calender-text {
  font-size: 0.9rem;
}

.table-font {
  font-size: 0.6rem;
}
.overview-img {
  margin: 15px auto;
  width: 400px;
  height: 230px;
}

.overview-para {
  font-size: smaller;
}

.text-success4 {
  color: #c6dd0eb3;
  font-weight: bolder;
}

.text-success3 {
  color: #c6dd0e;
  font-weight: bolder;
}

.text-success2 {
  color: #8abf14;
  font-weight: bolder;
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.text-success1 {
  color: #3c6d0e;
  font-weight: bolder;
}

.text-success0 {
  color: #154706;
  font-weight: bolder;
}

.text-danger0 {
  color: #cb212c;
  font-weight: bolder;
}

.text-danger1 {
  color: #ff4001;
  font-weight: bolder;
}

.text-danger2 {
  color: #ff7f00;
  font-weight: bolder;
}

.text-danger3 {
  color: #ffad09f2;
  font-weight: bolder;
}

.text-danger4 {
  color: #fdc625;
  font-weight: bolder;
}
.calenderInput {
  background-color: #ffffff00;
  border: none;
  color: #fff;
  width: 90px;
  font-size: 15px;
}
.font-size2 {
  font-size: 0.7rem;
}

@media (max-width: 1400px) {
  .overview-img {
    margin: 30px auto;
  }
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .overview-img {
    margin: 0px auto;
    width: 300px;
    height: 150px;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1100px) {
  .overview-img {
    margin: 0px auto;
    width: 350px;
    height: 200px;
  }
}

/* 
 */
.table-row {
  transition: transform 2s ease-in-out;
}

.table-row.move1 {
  transform: translateY(-50%);
}

.table-row.move2 {
  transform: translateY(20%);
}

.table-row.move3 {
  transform: translateY(10%);
}
