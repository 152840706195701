@keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  
  .slide-animation {
    animation: slide-up 1s ease-in-out;
  }
  