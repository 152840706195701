
.pd-table__dropdown__content:after, .pd-table__dropdown:after, .pd-table__header:after, .pd-table ul:after {
  content: "";
  display: table;
  clear: both;
}



/*===========================================
// Progressive Disclosure Comparison Table //
===========================================*/
.pd-table {
  margin: 0 ;
  background: #303138;
}

@media screen and (min-width: 1100px) {
  .pd-table-header-is-fixed header {
    position: fixed;
    z-index: 10;
    top: 0;
    margin: 0 auto;
    padding: 0 1em;
    max-width: 1400px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
}
.pd-table ul {
  width: 100%;
}

.pd-table li {
  overflow: hidden;
  min-height: 3.5rem;
  line-height: 3.5rem;
  float: left;
  left: 0;
}

@media screen and (max-width: 1200px) {
  .pd-table li {
    min-height: 3rem;
    
  line-height: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .pd-table li {
    min-height: 2.5rem;
    
    line-height: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .pd-table li {
    min-height: 2rem;
    
    line-height: 2rem;
  }
}

.pd-table li:not(:first-child) {
  width: 100%;
  max-width: calc((100% - 30%) / 4);
}
.pd-table li:first-child {
  width: 100%;
}
@media screen and (min-width: 100px) {
  .pd-table li:first-child {
    width: 30%;
  }
}
.pd-table__header {
  width: 100%;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
}
.pd-table__header ul {
  text-transform: uppercase;
  left: 0;
  font-size: 0.9rem;
  font-weight: 700;
  transition: all 0.4s ease;
  
}
.pd-table__header li {
  text-align: center;
  color: #414141;
  font-size: 16px;
  left: 0;
  top: 0;
  
}

@media screen and (max-width: 1200px){
  
  .pd-table__header li{
    font-size: 12px;
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media screen and (max-width: 940px){
  
  .pd-table__header li{
    font-size: 9px;
    position: relative;
    padding-left: 2px;
    padding-right: 2px;
  }
}
@media screen and (max-width: 600px){
  
  .pd-table__header li{
    font-size: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.pd-table__header li:not(:first-child) {
  display: none;
}
.pd-table__header li:nth-child(1) {
  background: #00608d;
}
.pd-table__header li:nth-child(2) {
  background: #80d7ff;
}
.pd-table__header li:nth-child(3) {
  background: #ebebeb;
}
.pd-table__header li:nth-child(4) {
  background: #ebebeb;
}
.pd-table__header li:nth-child(5) {
  background: #ebebeb;
}


@media screen and (min-width: 100px) {
  .pd-table__header li:not(:first-child) {
    display: initial;
  }
}
@media screen and (max-width: 1200px) {
  .pd-table__header li:not(:first-child) {
  height: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .pd-table__header li:not(:first-child) {
  height: 2.5rem;
  }
}
@media screen and (max-width: 600px) {
  .pd-table__header li:not(:first-child) {
  height: 2rem;
  }
}


.pd-table__row:nth-child(odd) .pd-table__cells {
  background: #fff;
  left: 0;
}
.pd-table__row:nth-child(even) .pd-table__cells {
  background: #f3fbff;
  left: 0;
}
.pd-table__row:nth-child(odd) .pd-table__cells-drop {
  background: #fff;
  color: #00608d;
  left: 0;
}
.pd-table__row:nth-child(even) .pd-table__cells-drop {
  background: #f3fbff;
  color: #00608d;
  left: 0;
}

.pd-table__cell {
  text-align: center;
  display: flex ;
  justify-content: center;
  align-items: center;
  max-height: 1rem;
  /* Title Cell
  ========================================*/
  /* Title Cell Button
  ========================================*/
}
.pd-table__row--has-dropdown .pd-table__cell {
  cursor: pointer;
}
.pd-table__cell:not(:first-child):not(:last-child) {
  border-right: 1px solid #dddddd;
}
.pd-table__cell:not(:first-child) {
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #dddddd;
}
.pd-table__cell[data-checked] {
  position: relative;
}

.pd-table__cell:not(.pd-table__cell--title) {
  display: none;
}
@media screen and (min-width: 100px) {
  .pd-table__cell:not(.pd-table__cell--title) {
    display: initial;
  }
}
.pd-table__cell--title {
  border-bottom: 1px solid #2a2a2d;
  background: #1a1a1a;
  color: #fff;
  max-height: 1rem;

  position: relative;
  transition: background 0.3s ease;
}
.pd-table__cell--title span {
  transition: transform 0.3s ease;
  padding: 0;
  transform: translate3d(0, 0, 0);
  float: left;
  position: relative;
}
.pd-table__row--has-dropdown:hover .pd-table__cell--title span, .pd-table__cells.active .pd-table__cell--title span {
  transform: translate3d(10px, 0, 0);
}
.pd-table__cell--title span::before {
  position: absolute;
  width: 1em;
  height: 1.6em;
  content: "";
  background-position: 50% 50%;
  background-repeat: no-repeat;
  left: 0;
  opacity: 0.3;
  top: 50%;
  transform: translateY(-50%);
}
.pd-table__row--sub .pd-table__cell--title {
  border-bottom: solid 1px #24252a;
  background: #292a30;
  color: #eeeeee;
}
.pd-table__row--sub .pd-table__cell-drop--title {
  color: #ffffff;
}
.pd-table__row--sub .pd-table__cell--title span {
  padding-left: 0;
}
.pd-table__row--sub .pd-table__cell--title span::before {
  position: relative;
  content: "—";
  padding-right: 0.5em;
  color: #5f616f;
}
.pd-table__cells.active .pd-table__cell--title {
  background: #484953;
}
.pd-table__row--has-dropdown .pd-table__cells:not(.active):hover .pd-table__cell--title {
  background: #3e3f48;
}
.pd-table__cell__button {
  margin: 0;
  padding: 0;
  float: right;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  transition: all 0.3s ease;
  cursor: pointer;
}
.pd-table__row--has-dropdown:hover .pd-table__cell__button, .pd-table__cells.active .pd-table__cell__button {
  transform: translate3d(0, 0, 0);
}
.pd-table__cell__button img {
  width: 100%;
  display: block;
  padding: 0;
  color: #fff;
  transform: rotateX(0deg);
  transition: 0.4s ease;
}
.pd-table__cells.active .pd-table__cell__button img {
  transform: rotateX(180deg);
}
.pd-table__dropdown {
  background: #fff;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pd-table__dropdown__eyebrow {
  text-transform: uppercase;
  color: #0a64a4;
  font-weight: 700;
  font-size: 0.8rem;
  letter-spacing: 0.03em;
  margin-bottom: 1.2em;
}

/* .pd-table__dropdown {
  overflow: hidden;
  max-height: 0;
  transition: max-height .5s ease-out;
}

.pd-table__row.active .pd-table__dropdown {
  max-height: 500px; 
  transition: max-height .5s ease-in;
} */


/* Tailwind classes or custom CSS */
.pd-table__dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.9s ease-in-out;
}

.pd-table__row.active .pd-table__dropdown {
  max-height: 200px; /* Adjust this value according to the content height */
}














.button-container {
  display: flex;
  justify-content: center;
}

/* Common styles for both buttons */
.brutalist-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  color: #383838;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* Styles for the first button */
.button-1 {
  background-color: #ffffff;
  border: 3px solid #5ab2ff;
  border-radius: 12px;
  box-shadow: 2px 2px 1px #000000;
}

.button-1:hover {
  background-color: #ffffff;
  border-color: #0088ff;
  transform: translate(-6px, -6px) rotate(1deg);
  box-shadow: 4px 4px 0 #000000, 5px 5px 10px rgba(64, 141, 164, 0.2);
}

.button-1::before,
.button-1::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.6s;
}

.button-1::before {
  left: -100%;
}
.button-1::after {
  left: 100%;
}

/* Hover effects */
.brutalist-button:hover .openai-logo {
  transform: translateY(-10px);
}

.brutalist-button:hover .openai-icon {
  width: 40px;
  height: 40px;
  margin-top: 6px;
}

.bruta.brutalist-button:hover .openai-text {
  opacity: 1;
  max-height: 50px;
}

/* Styles for the OpenAI logo and text */
.openai-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 3;
}

.openai-icon {
  width: 78px;
  height: 78px;
  margin-top: 0px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (max-width: 640px) {
  .openai-icon {
    width: 58px;
  height: 58px;
  }
}

.openai-text {
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  text-align: center;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  z-index: 3;
}

.button-text span {
  font-size: 16px;
}

@media (max-width: 640px) {
  .button-text span {
    font-size: 14px;
  }
}

/* Hover effects */
.brutalist-button:hover .openai-logo {
  transform: translateY(-10px);
}

.brutalist-button:hover .openai-icon {
  width: 50px;
  height: 50px;
  margin-top: 6px;
}

.brutalist-button:hover .button-text,
.brutalist-button:hover .openai-text {
  opacity: 1;
  max-height: 50px;
}

.brutalist-button:hover .openai-icon {
  animation: spin-and-zoom 4s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;
}

.brutalist-button:hover .openai-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.brutalist-button:active .openai-icon,
.brutalist-button:active .openai-text,
.brutalist-button:active .button-text {
  transform: scale(0.95);
}


.brutalist-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  color: #383838;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@media (max-width: 640px) {
  .brutalist-button {
    width: 80px;
  height: 80px;
  }
}

/* Styles for the first button */
.button-1 {
  background-color: #ffffff;
  border: 3px solid #5ab2ff;
  border-radius: 12px;
  box-shadow: 2px 2px 1px #000000;
}

/* Default active styles */
.brutalist-button--active,
.button-1.brutalist-button--active {
  background-color: #ffffff;
  border-color: #0088ff;
}

.brutalist-button--active::before,
.brutalist-button--active::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.1),
    transparent
  );
  transition: 0.6s;
}

.brutalist-button--active::before {
  left: -100%;
}
.brutalist-button--active::after {
  left: 100%;
}

/* Hover and active effects */
.brutalist-button:hover .openai-logo,
.brutalist-button--active .openai-logo {
  transform: translateY(-10px);
}

.brutalist-button:hover .openai-icon,
.brutalist-button--active .openai-icon {
  width: 50px;
  height: 50px;
  margin-top: 6px;
}

.brutalist-button:hover .button-text,
.brutalist-button--active .button-text,
.brutalist-button:hover .openai-text,
.brutalist-button--active .openai-text {
  opacity: 1;
  max-height: 50px;
}

.brutalist-button:hover .openai-icon,
.brutalist-button--active .openai-icon {
  animation: spin-and-zoom 4s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;
}

.brutalist-button:hover .openai-text,
.brutalist-button--active .openai-text {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.brutalist-button:active .openai-icon,
.brutalist-button:active .openai-text,
.brutalist-button:active .button-text {
  transform: scale(0.95);
}

/* Keyframes for neural-like wave effect */
@keyframes neuralEffect {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 100% ;
  }
  100% {
    background-position: 0 50% ;
  }
}

/* Style for the middle-line */
.middle-line {
  background-size: 200% 100%; 
  /* transition: background 0.5s ease-in-out; */
}

/* Class to trigger the neural effect */
.middle-line.animate {
  animation: neuralEffect 1s forwards;
}



/*  */
.animate-dashDown {
  stroke-width: 4;
  fill: none;
  stroke-dasharray: 15 85;
  animation-name: dashDown;
}

.animate-dashUp {
  stroke-width: 4;
  fill: none;
  stroke-dasharray: 15 85;
  animation-name: dashUp;
}

@keyframes dashDown {
  from {
    stroke-dashoffset: 45;
  }
  to {
    stroke-dashoffset: -30;
  }
}

@keyframes dashUp {
  from {
    stroke-dashoffset: -30;
  }
  to {
    stroke-dashoffset: 45;
  }
}

.animated-path-upDown {
  stroke-dasharray: 15 85;
  stroke-dashoffset: 50;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@media (max-width: 400px) {
  .mobile-stroke {
    stroke-width: 8px; /* Increase this value as needed */
  }
}



/* Define foldIn and foldOut animations for the entire table */
@keyframes tableFoldOut {
  0% {
    transform: rotateY(0deg);
    opacity: 1;
  }
  
  100% {
    transform: rotateY(180deg);
    opacity: 0;
  }
}

@keyframes tableFoldIn {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.pd-table-container {
  transform-origin: bottom left 120px;
  animation-duration: 2s;
  /* animation-fill-mode: forwards; */
}

.pd-table-container.hidden {
  display: none;
}



