/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css?family=Coda|Open+Sans");
:root {
  --primary: #3b71c9;
  --greyDark: #9baacf;
  --white: #ffffff;
  --greyLight-2: #c8d0e7;
  --shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem var(--white);
  --inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem var(--white);
}

.date-picker-date {
  font-size: 16px;
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
  transition: border 0.3s, color 0.3s, box-shadow 0.3s;
  font-weight: 400;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15), 3px 3px 5px 0 rgba(0, 0, 0, 0.06);
}

.date-picker-date:hover {
  cursor: pointer;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2), 3px 3px 5px 0 rgba(0, 0, 0, 0.15);
}
