/* Add this to your CSS stylesheet */

.hideCard {
  display: block;
}

@media (max-width: 1020px) {
  .hideCard {
    display: none;
  }
}
.showCard {
  display: none;
}

@media (max-width: 1020px) {
  .showCard {
    display: block;
  }
}

.portfolio {
  flex-direction: row;
}

@media (max-width: 1250px) {
  .portfolio {
    flex-direction: column;
  }
}
.portfolio2 {
  flex-direction: row;
}

@media (max-width: 1250px) {
  .portfolio2 {
    flex-direction: column-reverse;
  }
}

.empty-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-place-holder {
  border: 2px dotted #b7b7b7;
  color: #b7b7b7;
  padding: 10px;
  border-radius: 5px;
}

/*  */

.box-effect {
  margin-top: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  overflow: hidden;
}

.animated {
  animation-name: gennie;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  transition: 0.3s all;
  border-radius: 20px;
}

.reverse {
  animation-name: gennie2;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  transition: 0.3s all;
}

@keyframes gennie {
  0% {
    margin-top: 0px;
    transform: scale(0.2);
    clip-path: polygon(50% 0%, 50% 0%, 100% 100%, 0% 100%);
  }
  100% {
    margin-top: 300px;
    transform: scale(1);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes gennie2 {
  0% {
    margin-top: 300px;
    transform: scale(1.2);
  }
  100% {
    margin-top: 0px;
    transform: scale(1);
  }
}

.risk-heading {
  position: relative;
  padding: 1rem;
  font-weight: 500;
  font-size: 4rem;
  z-index: 21;
  color: #000;
}

.risk-heading2 {
  font-weight: 400;
  font-size: 5rem;
}

.gradient-border {
  position: relative;
  width: 1000px;
  height: 600px;
  right: 120px;
  top: 13vh;
  border: 16px solid transparent;
  border-image: linear-gradient(to right, #000, rgb(59, 113, 201));
  border-image-slice: 1;
}

.risk-img {
  position: relative;
  width: 1000px;
  height: 600px;
  right: 41px;
  top: 1vh;
  border: 16px solid transparent;
  border-image: linear-gradient(to right, #3b71ca, #000);
  border-image-slice: 1;
  border-radius: 10px;
}

.risk-para {
  padding: 0rem 1rem;
  font-size: 1.3rem;
  width: 80%;
}

.risk-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  padding: 10px;
  box-shadow: 0px 0px 9px -5px #000;
  height: 240px;
  border-radius: 5px;
  overflow: hidden;
}

/*  */

.risk-square {
  position: absolute;
  top: 13px;
  left: 13px;
  height: 230px;
  width: 230px;
  background-image: linear-gradient(to right, #3b71ca57, #00000052);
  z-index: 20;
}
.risk-square-border {
  position: absolute;
  height: 230px;
  width: 230px;
  background-image: linear-gradient(to right, #3b71ca57, #00000052);
  top: 130px;
  left: 28px;
  z-index: 20;
}

.risk-info-head {
  color: #000;
  padding: 20px;
  text-align: center;
  font-size: 3.3rem;
  font-weight: 300;
  background-image: linear-gradient(to right, #3b71ca57, #000);
  margin-bottom: 65px;
}

.info-round {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #3b72ca, #000);
  border-radius: 100%;
  padding: 14px;
  z-index: 10;
}
.info-container {
  margin: 0px 100px;
}

.info-text {
  color: #000;
  font-size: 3rem;
}

.info-icon {
  width: 180px;
}

.info-image {
  width: 400px;
}

.dotted-line {
  position: absolute;
  border-left: 3px solid #fff;
  left: 43px;
  z-index: 0;
  top: 0vh;
}

.dotted-line::before {
  background-color: red;
  position: absolute;
  border-left: 3px dotted #fff;
  left: 44px;
  animation: upToBottom 2s ease-out;
  z-index: 8;
}

.fixed-btn {
  margin: 0 0.5rem 1.25rem 0;
  padding: 0.625rem 1.25rem;
  background-image: linear-gradient(to right, #3b72ca, #000);
  border-radius: 9.9375rem;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  position: fixed;
  bottom: 3vh;
  right: 1vw;
  animation: upToBottom2 2s ease-out;
  z-index: 1;
}

.fixed-btn:hover {
  bottom: 3.5vh;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomScale {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideupFrombottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  60% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes upToBottom {
  from {
    height: 0px;
    opacity: 0;
  }
  to {
    height: 50px;
    opacity: 1;
  }
}

@keyframes upToBottom2 {
  0% {
    bottom: 91vh;
    right: 85vw;
    transform: scale(0);
  }
  60% {
    bottom: 3vh;
    right: 1vw;
    transform: scale(0.3);
  }
  100% {
    bottom: 3vh;
    right: 1vw;
    transform: scale(1);
  }
}

@keyframes bottomToTop {
  from {
    transform: translateY(200%);
  }
  to {
    transform: translateY(0);
  }
}

.animation1 {
  animation: slideInFromLeft 0.6s ease-out;
}

.type-btn {
  color: #3b72ca;
  border: 2px solid #fff;
  font-size: 0.9rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 5px;
}

.type-btn:hover {
  color: #3b71ca;
  border: 2px solid #3b71ca;
}

.risk-btn {
  margin: 0 0.5rem 1.25rem 0;
  padding: 0.625rem 1.25rem;
  background-image: linear-gradient(to right, #3b72ca, #000);
  border-radius: 9.9375rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
}

.risk-btn:hover {
  color: #fff;
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.animation-btn {
  position: relative;
  animation: zoomScale 1s ease-out;
}

.animation-btn:hover {
  top: 2px;
}

.animation-pic {
  animation: slideupFrombottom 1.2s ease-out;
}

.text-animation {
  margin: 60px 0px 0px 0px;
  overflow: hidden;
  width: 90%;
}

.step-count {
  position: relative;
  height: 5.5vh;
  width: 5.5vh;
  border: 2px solid #fff;
  display: flex;
  color: #fff;
  background-color: #000;
  align-items: center;
  justify-content: center;
  margin: 0px 20px;
  border-radius: 87%;
  z-index: 10;
}

.step-count:hover {
  color: #000;
  background-color: #fff;
}

@keyframes pop-btn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.dotted-line2 {
  position: relative;
  border-left: 2px dotted #fff;
  height: 100vh;
  left: 36px;
  z-index: 1;
}
/*  */
.tab {
  position: sticky;
  display: flex;
  flex-direction: column;
  height: 94vh;
  width: 13.4vw;
  z-index: 10;
  top: 6vh;
  background-color: #000;
}

.risk-steps {
  position: absolute;
  background: #000;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  width: 300px;
  word-wrap: none;
  color: #fff;
  padding: 11px 10px 10px 17px;
  z-index: -1;
  top: -7px;
  left: 67px;
  animation: roll-out 0.5s;
  animation-iteration-count: 1;
  transition: 0.5 all;
}

.icons {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: 94vh;
  justify-content: space-around;
}

.circle {
  position: absolute;
  height: 5.6rem;
  background-position: center;
  background-size: cover;
  margin: 0px;
  width: 5.6rem;
  transition: all 0.8s;
}

/* LEVEL-1 STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.level-1 {
  font-size: 1.3rem;
}

.level-3 {
  padding: 0px 35px;
}

.middle-line {
  border-left: 2px solid #000;
  height: 30px;
}

.arrowToNext1 {
  border: 2px solid #000;
  border-bottom: none;
  height: 30px;
  width: 545px;
}

@media (max-width: 1460px) {
  .arrowToNext1 {
    height: 30px;
    width: 400px;
  }
}

@media (max-width: 560px) {
  .arrowToNext1 {
    height: 30px;
    width: 210px;
  }
}

@keyframes swing {
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(10%);
  }
}

@keyframes popped {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.popImage {
  height: 300px;
  border-radius: 10px;
  width: 400px;
}

.carousel-style1 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 3px 0px #757575;
  width: 900px;
  height: 500px;
}

.carousel-control-prev-icon {
  color: #000 !important;
}

.carousel-control-next-icon {
  color: #000 !important;
}

.pop-tooltip {
  border-radius: 3px;
  position: absolute;
  top: 21px;
  width: 300px;
  transform: translateX(-200px);
}

.risk-nature-tile {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in;
  background: #ffffff;
  box-shadow: 0px 0px 1.29915px rgba(0, 0, 0, 0.19),
    0px 1.29915px 2.59829px rgba(0, 0, 0, 0.07),
    0px 10px 18px -6.49573px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
}
.selected-tile {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px 0.5px 1.5px rgba(0, 0, 0, 0.12);
  border-radius: 13px;
}

.risk-nature-pic {
  width: 75px;
  height: 75px;
  border: 1px solid grey;
  border-radius: 50%;
  margin: 0px 20px 0px 0px;
}

.risk-nature-pic2 {
  height: 347px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.risk-nature-card {
  background: #f4f2f1;
  border-radius: 24px;
  padding: 0.75rem;
  height: 100%;
}

.risk-nature-card2 {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.risk-nature-para {
  margin: 0px;
  padding: 10px 20px 0px 20px;
  text-align: justify;
  font-size: 1.4rem;
  border-top: 1px solid #f2f0ef;
  background: #f2f0ef;
  height: -webkit-fill-available;
}

.risk-nature-title {
  padding: 30px;
  margin: 0px;
  text-align: center;
  font-size: 35px;
}

.animated2 {
  animation-name: gennie3;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  transition: 0.3s all;
}

@keyframes gennie3 {
  0% {
    transform: scale(0.2);
    clip-path: polygon(50% 0%, 50% 0%, 100% 100%, 0% 100%);
  }
  100% {
    transform: scale(1);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

@keyframes roll-out {
  0% {
    width: 0px;
    opacity: 0;
  }
  100% {
    width: 300px;
    opacity: 1;
  }
}

.card {
  background: rgba(172, 7, 226, 0.5);
  background-image: url(/public/images/market-beta.webp);
  background-size: cover;
  position: relative;
  max-width: 320px;

  border-radius: 15px;
  padding: 1rem;
  padding-bottom: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 7.5px;
  transition: 0.5s ease;
  color: white;
}

@media (max-width: 1060px) {
  .card {
    max-width: 220px;
    height: 300px;
  }
  .cardHeader {
    font-size: 1rem;
  }
}
.card2 {
  background: rgba(172, 7, 226, 0.5);
  background-image: url(/public/images/economic-con.webp);
  background-size: cover;
}
.card3 {
  background: rgba(172, 7, 226, 0.5);
  background-image: url(/public/images/changes.webp);
  background-size: cover;
}

.card:hover::after {
  opacity: 0.52;
}

.cardHeader {
  text-transform: uppercase;
  position: relative;
  width: max-content;
  font-weight: bold;
  transition: all 0.5s ease;
  color: #5af;
  font-size: 1.5rem;
}
.cardHeader::after {
  content: "";
  width: calc(100% + 1rem);
  height: 2.5px;
  transform: translateX(calc(-100% - 1rem));
  background: aqua;
  bottom: -2px;
  left: 0;
  position: absolute;
  opacity: 0;
}

.details {
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateY(95%);
  transition: all 0.5s ease;
}

@media (max-width: 1060px) {
  .card {
    max-width: 220px;
    height: 300px;
  }
  .cardHeader {
    font-size: 1rem;
  }
  .cardText {
    font-size: 0.7rem;
  }
}
@media (max-width: 810px) {
  .card {
    max-width: 200px;
    height: 220px;
    padding: 1rem;
    padding-left: 0.25rem;
  }
  .cardHeader {
    font-size: 0.7rem;
  }
  .cardText {
    font-size: 0.5rem;
  }
}
@media (max-width: 738px) {
  .card {
    max-width: 140px;
    height: 200px;
    padding: 1rem;
    padding-left: 0.25rem;
  }
  .cardHeader {
    font-size: 0.6rem;
  }
  .cardText {
    font-size: 0.5rem;
  }
}
@media (max-width: 366px) {
  .card {
    max-width: 130px;
    height: 200px;
    padding: 1rem;
    padding-left: 0.25rem;
  }
  .cardHeader {
    font-size: 0.6rem;
  }
  .cardText {
    font-size: 0.5rem;
  }
}

.button {
  background-color: aqua;
  color: black;
  padding: 2.5px 5px;
  width: max-content;
  border-radius: 2.5px;
}
.card:hover .details {
  transform: translateY(0%);
  transition-delay: 0.5s;
}
.card:hover .cardHeader::after {
  transform: translateX(-1rem);
  transition: 0.5s ease;
  opacity: 1;
}
.card:hover {
  transform: scale(1.1);
  border-radius: 15px;
}

.cards {
  display: flex;
  gap: 30px;
}

.cards .blur-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 320px;
  width: 350px;
  border-radius: 10px;
  color: #313131;
  cursor: pointer;
  transition: 400ms;
  box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.1),
    0px 1px 0px 0px rgba(25, 28, 33, 0.02),
    0px 0px 0px 1px rgba(25, 28, 33, 0.08);
  background-color: #fff;
}

.cards .blur-card:hover {
  transform: scale(1.1, 1.1);
}

.cards:hover > .blur-card:not(:hover) {
  filter: blur(10px);
  transform: scale(0.9, 0.9);
}

@media (max-width: 1060px) {
  .cards {
    gap: 20px;
  }
  .cards .blur-card {
    height: 240px;
    width: 270px;
  }
}
@media (max-width: 1024px) {
  .cards {
    gap: 20px;
  }
  .cards .blur-card {
    height: 270px;
    width: 200px;
  }
}
@media (max-width: 500px) {
  .cards {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cards .blur-card {
    height: 250px;
    width: 180px;
  }
}
@media (max-width: 400px) {
  .cards .blur-card {
    height: 250px;
    width: 149px;
  }
}

/* === removing default button style ===*/
.risk-button {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

/* risk-button styling */
.risk-button {
  --border-right: 6px;
  --text-stroke-color: #414141;
  --animation-color: #5af;
  --fs-size: 1.25rem;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on risk-button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.risk-cal-button:hover .hover-text {
  width: 100%;
  /* filter: drop-shadow(0 0 23px var(--animation-color)) */
}

.item-hints {
  /* --purple: #720c8f; */
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.item-hints .hint {
  margin: 10px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-hints .hint-dot {
  z-index: 3;
  -webkit-transform: translate(-0%, -0%) scale(0.95);
  transform: translate(-0%, -0%) scale(0.95);
}
.item-hints .hint-radius {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0 0 -125px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.item-hints .hint[data-position="1"] .hint-content {
  top: 85px;
  left: 50%;
  margin-left: 36px;
}
.item-hints .hint-content {
  width: 400px;
  position: absolute;
  z-index: 5;
  padding: 0px 0;
  opacity: 0;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #212121;
  visibility: hidden;
  pointer-events: none;
}

@media (max-width: 1500px) {
  .item-hints .hint-content {
    width: 260px;
    position: absolute;
    z-index: 5;
    padding: 0px 0;
    opacity: 0;
    transition: opacity 0.7s ease, visibility 0.7s ease;
    color: #212121;
    visibility: hidden;
    pointer-events: none;
  }
}

@media (max-width: 1060px) {
  .item-hints .hint {
    margin: 10px 0;
  }
  .item-hints .hint-content {
    width: 500px;
  }
}
@media (max-width: 950px) {
  .item-hints .hint {
    margin: 10px 0;
  }
  .item-hints .hint-content {
    width: 300px;
  }
}

.item-hints .hint:hover .hint-content {
  position: absolute;
  z-index: 5;
  padding: 0px 0;
  opacity: 1;
  -webkit-transition: opacity 0.7s ease, visibility 0.7s ease;
  transition: opacity 0.7s ease, visibility 0.7s ease;
  color: #212121;
  visibility: visible;
  pointer-events: none;
}
.item-hints .hint-content::before {
  /* width: 0px;
  bottom: 29px;
  left: 0; */
  content: "";
  background-color: #212121;
  height: 0px;
  position: absolute;
  transition: width 0.4s;
}
.item-hints .hint:hover .hint-content::before {
  width: 180px;
  transition: width 0.4s;
}
.item-hints .hint-content::after {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  bottom: 23px;
  left: 0;
  width: 50px;
  content: "";
  background-color: #5af;
  height: 2px;
  position: absolute;
  opacity: 1;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.item-hints .hint:hover .hint-content::after {
  opacity: 1;
  visibility: visible;
}
.item-hints .hint[data-position="4"] .hint-content {
  bottom: 0px;
  left: 100%;
  margin-left: 56px;
}

@media (max-width: 500px) {
  .item-hints {
    padding-right: 20px;
  }
  .item-hints .hint-content {
    width: 170px;
  }
  .item-hints .hint:hover .hint-content::before {
    width: 80px;
  }

  .item-hints .hint-content::after {
    width: 10px;
  }
  .item-hints .hint[data-position="4"] .hint-content {
    margin-left: 20px;
  }
}

/*  */
/*Bottom Left rotated line*/
.animated-path1 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash2 3s linear infinite;
}
/*Top right rotated line */
.animated-path4 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash2 6s linear infinite;
}
/*Bottom Right rotated line*/
.animated-path2 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash 12s linear infinite;
}
/* Top Left rotated line */
.animated-path3 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash 10s linear infinite;
}
/* Middle right line */
.animated-path5 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash 8s linear infinite;
}
/* Middle left line */
.animated-path6 {
  stroke-width: 0.6;
  fill: none;
  stroke-dasharray: 15 85;
  animation: dash 7s linear infinite;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
  90% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 100;
    opacity: 1;
  }
}

@keyframes dash2 {
  0% {
    stroke-dashoffset: 100;
    opacity: 1;
  }
  15% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 0;
  }
}

.animated-background {
  background: linear-gradient(270deg, #fcfcfc, #ffffff, #fcfcfc);
  background-size: 200% 200%;

  -webkit-animation: animated-background 2s ease infinite;
  animation: animated-background 2s ease infinite;
}

@-webkit-keyframes animated-background {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@keyframes animated-background {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.element {
  transform: translate(0px, 0px);
  animation: element 2s 1 ease-out 204ms;
}

@keyframes element {
  from {
    transform: translate(0px, 0px);
    display: block;
  }

  to {
    transform: translate(900px, 0px);
    display: none;
  }
}
.element2 {
  transform: translate(0px, 0px);
  animation: element2 500ms 1 ease-in 0ms;
}

@keyframes element2 {
  from {
    transform: translate(-500px, 0px);
    opacity: 0;
  }

  to {
    transform: translate(0px, 0px);
    opacity: 100;
  }
}

.element-reverse {
  transform: translate(0px, 0px);
  animation: element-reverse 1s 1 ease-in-out;
}

@keyframes element-reverse {
  to {
    transform: translate(40px, 0px);
    display: block;
  }

  from {
    transform: translate(600px, 0px);
    display: none;
  }
}

.button-r {
  color: #313131;
  position: relative;
  transition: border ease-in-out 0.2s;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.button-r > p {
  transition: 0.3s;
  margin-left: 1rem;
}

.button-r > i:first-child {
  position: absolute;
  transition: 0.3s;
  left: -100px;
}

.button-r:hover {
  transition: 0.3s;
}

.button-r:hover > p {
  transition: 0.3s;
  margin-right: 0;
  margin-left: 3rem;
}

.button-r:hover > i:first-child {
  transition: ease-in-out 0.3s;
  left: 20px;
}

.range {
  height: 5px;
  /* appearance: none; */
  width: 100%;
  margin: 2px;
  border: none;
  border-radius: 8px;
  transition: 0.2s ease;
  /* background-color: #5e5e5e; */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* display: none; */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #5ab2ff;
  cursor: pointer;
  transition: ease-in-out 0.2s;
}

/* input[type="range"]:hover {
  background: #5ab2ff;
} */
input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #0088ff;
  box-shadow: 0px 0px 0px 4px #4299e160;
}

/* Styling the options */
.amount-select option {
  display: flex;
  text-align: center;
  background-color: #fff;
  /* color: #333; */
}

/* Changing background color of options on hover */

/* display the heading and svg in a centered column */
section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1rem;
}
section h1 {
  font-weight: 800;
  font-size: 1.8rem;
}
/* have the svg occupy a sizeable portion of the wrapping container */
section svg {
  margin-top: 2.5rem;
  width: 90%;
  height: auto;
}

.blob-container {
  min-height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;

  /* filter: blur(5px);  */
}

.img-risksensor {
  opacity: 50%;
}

.blob.one {
  background: #ff6384;
  height: 200px;
  width: 200px;
  left: 10px;
  top: 10px;
  animation: movement_two 18s ease-in-out infinite both;
}

.blob.two {
  background: #36a2eb;
  height: 200px;
  width: 200px;
  left: 1000px;
  top: 30px;
  animation: movement_two 21s ease-in-out infinite both;
}

.blob.three {
  background: #ffce56;
  height: 160px;
  width: 160px;
  left: 500px;
  top: 60px;
  animation: movement_one 12s ease-in-out infinite both;
}

.blob.four {
  background: #4bc0c0;
  height: 120px;
  width: 120px;
  left: 480px;
  top: 250px;
  animation: movement_one 16s ease-in-out infinite both;
}

.blob.five {
  background: #9966ff;
  height: 100px;
  width: 100px;
  left: 150px;
  top: 400px;
  animation: movement_two 7s ease-in-out infinite both;
}

.blob.six {
  background: #ff9f40;
  height: 130px;
  width: 130px;
  left: 400px;
  top: 600px;
  animation: movement_two 12s ease-in-out infinite both;
}

.blob.seven {
  background: #c9cbcf;
  height: 130px;
  width: 130px;
  left: 280px;
  top: 40px;
  animation: movement_two 13s ease-in-out infinite both;
}

.blob.eight {
  background: #ff6384;
  height: 130px;
  width: 130px;
  left: 700px;
  top: 400px;
  animation: movement_two 16s ease-in-out infinite both;
}

.blob.nine {
  background: #36a2eb;
  height: 160px;
  width: 160px;
  left: 850px;
  top: 600px;
  animation: movement_two 10s ease-in-out infinite both;
}

.blob.ten {
  background: #4bc0c0;
  height: 120px;
  width: 120px;
  left: 20px;
  top: 700px;
  animation: movement_one 11s ease-in-out infinite both;
}

.blob.eleven {
  background: #9966ff;
  height: 130px;
  width: 130px;
  right: 280px;
  top: 100px;
  animation: movement_two 13s ease-in-out infinite both;
}

.blob.twelve {
  background: #ff9f40;
  height: 130px;
  width: 130px;
  right: 100px;
  top: 600px;
  animation: movement_two 16s ease-in-out infinite both;
}

.blob.thirteen {
  background: #c9cbcf;
  height: 160px;
  width: 160px;
  right: 350px;
  top: 500px;
  animation: movement_two 10s ease-in-out infinite both;
}

.blob.fourteen {
  background: #cdefe7;
  height: 120px;
  width: 120px;
  right: 320px;
  top: 400px;
  animation: movement_one 11s ease-in-out infinite both;
}

@keyframes movement_one {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: translate(70%, 20%) scale(1);
  }
}

@keyframes movement_two {
  0%,
  500% {
    transform: none;
  }
  50% {
    transform: translate(20%, 80%) scale(1.3);
  }
}

.cal-card {
  overflow: hidden;
  position: relative;
}

.cal-card:before {
  content: "";
  position: absolute;
  height: 150%;
  width: 3em;
  background-color: rgb(240, 240, 240);
  opacity: 0.14;
  animation: glider 4s infinite linear;
  animation-delay: 0.05s;
}

.cal-card:after {
  content: "";
  position: absolute;
  height: 250%;
  width: 1em;
  background-color: rgb(255, 255, 255);
  opacity: 0;
  animation: glider 4s infinite linear;
}

@keyframes glider {
  0% {
    transform: rotate(45deg) translate(-40em, -30em);
  }

  100% {
    transform: rotate(45deg) translate(20em, -10em);
  }
}

/* Animation Responsive ness */
@media (max-width: 1024px) {
  .hidden-765 {
    display: none;
  }
}
@media (min-width: 765px) {
  .hidden2-765 {
    display: none;
  }
}
@media (max-width: 765px) {
  .hidden3-765 {
    display: none;
  }
}

/*  */
.demo-risk-container {
  height: 100vh;
  position: fixed;
  background: #000000a3;
  top: 0px;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Custom styles for carousel indicators (dots) */
.carousel-indicators .carousel-dots button {
  background-color: rgb(59, 59, 59) !important; 
  position: relative;
  top: 30px;
}

.carousel-indicators .carousel-dots .active {
  background-color: #000000; /* Ensure active dot is also black */
}
