.demo1-container {
  height: 100vh;
  position: fixed;
  background: #0a0a0ae8;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo2-container {
  height: 100vh;
  position: fixed;
  background: #000000a3;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo3-container {
  height: 100vh;
  position: fixed;
  background: #0000005d;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.demo5-container {
  height: 100vh;
  position: fixed;
  background: linear-gradient(to top, #00000021, #ffffff07);
  width: 100vw;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: end;
}
.pop2-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;

  width: 50vh;
  border: 1px solid grey;

}

.pop3-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;

  width: 35vh;
  border: 1px solid grey;

}
.pop1-content {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid grey;
}
.pop-img {
  width: 100%;
  height: 100%;
}
.pop-text {
  word-wrap: break-word;
  text-align: center;
}

.pop2 {
  position: absolute;
  z-index: 10;
  border-radius: 10px;
  width: 180px;
}
