
.otp-icon {
  border-radius: 100%;
  background: #3b71ca;
  padding: 20px;
}

/*  */

.circle {
  width: 40px;
  height: 40px;
  background: transparent;
  border: 4px solid #e91e63;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.circle[data-animation="magnify"]:not(.magnify) {
  transition: 100ms ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
}
.circle.magnify {
  transition: 200ms ease-in-out;
  transform: scale(1.6);
  opacity: 0;
}
.fromMiddle {
  &:before,
  &:after {
    z-index: 9999;
    transition-delay: 150ms;
    transition: 400ms ease-in-out;
  }
  span {
    width: 38px;
    height: 38px;
    background-color: #e91e63;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    left: -2px;
    top: -2px;
    z-index: -9999;
    transform: scale(0.3);
    opacity: 0;
    transition: 300ms ease-in-out;
  }
}
.fromMiddle:hover {
  &:before,
  &:after {
    position: absolute;
    background-color: #fff;
  }
  span {
    transform: scale(1);
    opacity: 1;
  }
}
.circle:before {
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.circle:after {
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.profile-img {
  display: block;
  width: 100%;
}

.pencil-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black; /* Adjust color as needed */
  opacity: 0;
  transition: opacity 0.5s;
}

.profile-img-container:hover .profile-img {
  opacity: 0.1;
}

.profile-img-container:hover .pencil-icon {
  opacity: 1;
}

