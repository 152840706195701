
.bid-searchIcon-style{
    background-color: #D8E5F9;
    color:black;
}
.bid-heading{
    border-radius: 12px;
    color: black;
    font-weight: 700;
    font-size: larger;
}

  