* {
  font-family: "Open Sans", sans-serif !important;
}

.blog-container {
  background-color: #8080801a;
}

.blog-container2 {
  display: flex;
  flex-direction: row;
}

.blogs-title {
  font-size: 1.3rem;
  font-weight: 100;
}

.blogs-date {
  font-size: 0.7rem;
}

.blogs-para {
  text-align: justify;
  font-size: 0.8rem;
  color: rgb(83, 80, 80);
}

.SearchList_Container {
  padding: 0px 0px;
  position: relative;
  border-radius: 0px;
  top: 0px;
  width: 580px;
  background-color: #fff;
  box-shadow: 0 0px 3px 0 rgb(0 0 0 / 17%);
}

.SearchList_Item {
  padding: 5px 10px;
  font-size: 0.9rem;
  border-bottom: 1px solid #e0e0e0;
}

.SearchList_Item:hover {
  background-color: #f0f0f0;
}

.SearchList_Item_Name {
  display: inline;
  padding-left: 10px;
}

.blog-head {
  height: 40vh;
}

.border-text-style {
  border: none;
  border-bottom: 2px solid #000;
  width: 55vw;
  height: 8vh;
}

.comment-logo {
  display: flex;
  border-radius: 50%;
  background: #000;
  font-size: 25px;
  color: #fff;
  height: 44px;
  width: 44px;
  align-items: center;
  justify-content: center;
}

.comment-font-size1 {
  font-size: 14px;
}

.sort-btn {
  padding: 5px 20px;
  font-weight: 400;
  font-size: 0.8rem;
}
.sort-btn:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.slice div {
  background: linear-gradient(
    120deg,
    #dbeafe 30%,
    #ffff 38%,
    #ffff 40%,
    #dbeafe 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: slice 2s infinite;
}

.transition-style {
  transform: translate(10px, 0px);
}

@keyframes slice {
  100% {
    background-position: -100% 0;
  }
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .blogs-para {
    text-align: justify;
    font-size: 0.7rem;
    color: rgb(83, 80, 80);
  }

  .blog-head {
    height: 20vh;
  }

  .blog-container2 {
    flex-direction: column;
  }
}

/* Media queries for medium screens (between 577px and 976px) */
@media (min-width: 577px) and (max-width: 976px) {
  .blog-head {
    height: 30vh;
  }

  .blogs-para {
    text-align: justify;
    font-size: 0.6rem;
    color: rgb(83, 80, 80);
  }
}

.text-share {
  
  overflow: hidden;

  padding: 5px;
}

.text-share-box {
  width: 300px;
  font-size: 0.8rem;
}


.keywords-container {
  position: relative;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.arrow-button {
  background: transparent;
  border: none;
  transition: opacity 0.5s ease;
}

