.scroller-style {
  scrollbar-width: thin;
}
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.accordion-animation {
  overflow: hidden;
  animation: swipe-down;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  z-index: 10;
}
.demo10-container {
  height: 100vh;
  position: fixed;
  background: #0a0a0a38;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes swipe-down {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
