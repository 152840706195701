@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap");
* {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

:root {
  --primary-color: #5ab2ff;
  --secondary-color: #dbedf9;
  --text: #212121;
  --white: #fff;
  --black: #000;
  --red: #c20202;
  --links: #89c8ff;
  --footer-col: #2f2f2f;
}

.before-footer {
  padding-top: 116px;
}

@media (max-width: 991px) {
  .before-footer {
    padding-top: 40px;
  }
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.showw {
  display: none;
}

.footer {
  position: relative;
  z-index: 1;
  background-color: var(--footer-col);

  /* padding: 2rem 4rem; */
}

.usefulLInks {
  font-size: 0.8rem !important;
}

.footer-title {
  /* height: 150px; */
  font-size: 1.6rem;
  font-weight: 500;
  background-color: var(--footer-col);
  color: var(--links);
  padding-top: 1rem;
  /* text-align: center; */
}

.bottom-line {
  height: 0.2rem;
  width: 20%;
  background-color: var(--primary-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.content-container {
  background-color: var(--footer-col);
  /* height: 500px; */
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--white);
  overflow: hidden;
}

.container1 {
  width: max-content;
  max-width: 340px;
  padding: 1rem;
}

.c1-icon {
  position: relative;
  top: -3px;
  margin-right: 0.5rem;
}

.a-icon {
  position: relative;
  top: -2px;
  margin-right: 0.2rem;
}

ul {
  position: relative;
  line-height: 2rem;
  left: 0.5rem;
}

.li {
  font-size: 1rem;
}

.li-p {
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--secondary-color);
}

.li-a {
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-weight: 500;
  color: var(--links);
}

.head-a {
  /* text-decoration: none; */
  /* text-decoration: underline; */
  text-decoration-thickness: 1px;
  text-decoration-skip-ink: none;
  text-underline-offset: 2px;
  color: var(--white);
}
.head-a:hover {
  text-decoration: underline;
  text-decoration-thickness: 0.1rem;
  text-decoration-skip-ink: none;
  text-underline-offset: 1px;
  color: var(--secondary-color);
}

.f-button {
  background-color: var(--footer-col);
  color: var(--white);
  /* padding: 10px 15px; */
  font-size: 24px;
  font-weight: 500;
  border: 2px solid transparent;
  /* border-radius: 15px; */
  cursor: pointer;
}

.footer-design {
  margin-left: 0px;
  display: none;
  z-index: 1;
  min-width: 200px;
  width: 380px;
  transition: all 2s linear;
}

.f-border-style-try {
  border-left: 4px solid var(--links);
}

.footer-design a {
  color: var(--white) !important;
  font-size: 1rem;
  margin-right: 4rem;
  font-weight: 500;
  text-decoration: none;
  /* display: block; */
  transition: 0.5s;
}

.footer-design a:hover {
  background-color: var(--primary-color) !important;
  color: var(--white);
}

.footer-design a:focus {
  background-color: var(--secondary-color) !important;
  color: var(--text);
}

.paste-button:hover button {
  border-radius: 15px 15px 0px 0px;
}

.sidebar-icon-drop {
  padding-left: 3rem;
}
.sidebar-icon-drop2 {
  padding-left: 4.3rem;
}

.sidebar-mini-icon-drop {
  margin-left: 1rem;
}

.footer-mini-icon-drop {
  margin-right: 5px;
}

.footer-bottom {
  height: 60px;
  width: 100%;
  background-color: var(--white);
  padding: 0 2rem;
  /* padding-top: 1rem; */
}

.f-left-bottom {
  font-size: 1rem;
  font-weight: 600;
}

.f-bottom-text {
  /* text-align: center; */
  font-size: 0.8rem;
  font-weight: 700;
}

/* .right-bottom {
  width: 50%;
} */

.i-button {
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: none;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transition: 0.1s;
}
.i-button svg {
  color: white;
  width: 20px;
  height: 20px;
  z-index: 9;
}
.i-button:nth-child(1) {
  background: linear-gradient(120deg, #0773b6, #0099ff, #1a9cff);
}

.i-button:nth-child(2) {
  background: linear-gradient(120deg, #ec05be, #f53939, #dfb732);
}

.i-button:nth-child(5) {
  background: linear-gradient(120deg, #03619c, #0073d7, #398fff);
}

.i-button:nth-child(4) {
  background: linear-gradient(120deg, #bb0808, #fa1d1d, #ff0303);
}

.i-button:nth-child(3) {
  background: linear-gradient(120deg, #1b1b1b, #404040, #2e2e2e);
}


.i-button:active {
  transform: scale(0.85);
}

.i-button::before {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  background-color: #212121;
  border-radius: 50px;
  z-index: -1;
  border: 0px solid rgba(255, 255, 255, 0.411);
  transition: 0.4s;
}

.i-button:hover::before {
  width: 0px;
  height: 0px;
}

.logo-left {
  padding-top: 2rem;
}

.logo-left img {
  filter: grayscale(100%);
}

.footerLogo {
  height: 70px;
  width: 70px;
}

.footerLeftText {
  font-size: 0.8rem;
  padding-top: 1rem;
}

.footerLine {
  height: 220px;
  width: 4px;
  background-color: var(--primary-color);
}

@media screen and (max-width: 1440px) {
  .footer-title {
    font-size: 1.8rem;
  }

  .bottom-line {
    height: 0.15rem;
    width: 20%;
  }

  .footerLogo {
    height: 60px;
    width: 60px;
  }

  .container1 {
    max-width: 320px;
  }

  .footer-bottom {
    height: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .footer-title {
    font-size: 1.5rem;
  }

  .bottom-line {
    height: 0.1rem;
    width: 20%;
  }

  .footerLogo {
    height: 50px;
    width: 50px;
  }

  .footerLeftText {
    font-size: 0.7rem;
  }

  .container1 {
    max-width: 300px;
  }

  .container-head {
    font-size: 1rem;
    svg {
      height: 25px;
      width: 25px;
    }
  }

  .footer-bottom {
    height: 50px;
  }

  .li {
    font-size: 0.8rem;
  }

  .li-p {
    font-size: 0.7rem;
    line-height: 0.8rem;
  }

  .li-a {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  .f-bottom-text {
    font-size: 0.7rem;
  }
  .bulltet-points {
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 768px) {
  .footerLogo {
    height: 40px;
    width: 40px;
  }

  .footerLeftText {
    font-size: 0.6rem;
    padding-top: 0.6rem;
  }

  .container1 {
    max-width: 215px;
  }

  .container-head {
    font-size: 1rem;
    svg {
      height: 20px;
      width: 20px;
    }
  }

  .footer-bottom {
    height: 50px;
  }

  .li {
    font-size: 0.7rem;
  }

  .li-p {
    font-size: 0.6rem;
    line-height: 0.7rem;
  }

  .li-a {
    font-size: 0.6rem;
    line-height: 0.7rem;
  }

  .f-bottom-text {
    font-size: 0.7rem;
  }

  .footerLine {
    height: 200px;
    width: 2px;
    background-color: var(--primary-color);
  }

  .bulltet-points {
    line-height: 1rem;
  }

  .footer-bottom {
    height: 40px;
    padding: 0 1rem;
  }
  .i-button::before {
    width: 30px;
    height: 30px;
  }

  .i-button {
    width: 20px;
    height: 20px;
  }
  .i-button svg {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 500px) {
  .hidee {
    display: none !important;
  }

  .showw {
    display: flex;
  }

  .footer-title {
    font-size: 1.2rem;
    padding: 0;
  }

  .logo-left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
  }

  .footerLeftText {
    font-size: 0.5rem;
    padding-top: 0.1rem;
  }

  .topsecc {
    padding: 0rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-p {
    padding-left: 15px;
    /* width: 370px; */
  }

  .footer-title {
    /* padding-left: 2rem; */
    font-size: 1rem;
  }

  .bottom-line {
    width: 40%;
  }

  .content-container {
    flex-direction: column;
    float: left !important;
    padding: 0 2rem;
  }

  .container1 {
    max-width: 300px;
  }

  .container-head {
    font-size: 1.2rem;
    svg {
      height: 25px;
      width: 25px;
    }
  }

  .footer-bottom {
    height: 50px;
  }

  .li {
    font-size: 0.9rem;
  }

  .li-p {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .li-a {
    font-size: 0.8rem;
    line-height: 1rem;
  }

  .f-bottom-text {
    font-size: 6px;
    font-weight: 900;
  }

  .logo-left {
    flex-direction: row !important;
  }

  .footerLine {
    height: 200px;
    width: 2px;
    transform: rotate(90deg);
  }

  .bulltet-points {
    i {
      font-size: 8px;
    }
    line-height: 1.3rem;
  }

  .bulltet-points-new {
    font-size: 8px;
  }

  .footer-bottom {
    height: 40px;
    padding: 0 1rem;
  }
  .i-button::before {
    width: 20px;
    height: 20px;
  }

  .i-button {
    width: 15px;
    height: 15px;
  }
  .i-button svg {
    width: 10px;
    height: 10px;
  }
}
