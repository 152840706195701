.font-table {
  font-size: 0.8rem;
}

.accordion-button {
  padding: 0.5rem;
}

.accordion-body {
  background: #ebebeb;
  border-radius: 0.5rem;
}

.apexcharts-menu-item {
  padding: 0px 5px !important;
}
.btn7 {
  width: 100%;
}

.btn3 {
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 5px;
  display: block;
  border-radius: 25px;
  transition: all 0.2s;
  box-shadow: 0px -2px 2px 0px black;
}
.btn3:hover {
  transform: translateY(-3px);
  box-shadow: none;
}

.text-justify-style {
  text-align: justify;
}

.info-style {
  height: 120px;
  width: 120px;
  animation: move 2s;
}
@media (max-width: 1500px) {
  .info-style {
    height: 100px;
    width: 100px;
    animation: move 2s;
  }
}

.popup1-style {
  position: absolute;
  background: #ffffff;
  width: -webkit-fill-available;
  height: 110vh;
  top: 3.6rem;
  left: 0px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
}

.closePopUp {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
}

.flex-style {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.banner-head2 {
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
  color: #8d5c00;
}
.banner {
  font-size: 5rem;
  font-family: sans-serif;
  font-weight: bolder;
  background: -webkit-linear-gradient(#333, #9fa6b2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.apexcharts-pie-slice:not(.apexcharts-pie-slice-expand) {
  transform: scale(20);
  transform-origin: center;
  transition: transform 0.2s ease;
}

.img-demo {
  animation-name: fade-img;
  opacity: 0;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

.pointer-demo3 {
  height: 20px;
  width: 20px;
  animation-name: point3;
  position: relative;
  z-index: 11;
  top: -171px;
  right: -320px;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}
@keyframes point3 {
  0% {
    top: -171px;
    right: -320px;
  }
  16% {
    top: -41px;
    right: -274px;
  }
  20% {
    top: -41px;
    right: -274px;
    transform: rotateX(0deg);
  }
  22% {
    top: -41px;
    right: -274px;
    transform: rotateX(45deg);
  }
  24% {
    top: -41px;
    right: -274px;
    transform: rotateX(0deg);
  }
  100% {
    top: -41px;
    right: -274px;
  }
}

.demo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.img-size {
  height: 300px;
}

@keyframes fade-img {
  0%,
  24% {
    opacity: 0;
  }
  25%,
  100% {
    opacity: 1;
  }
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .flex-style {
    justify-content: space-between;
    flex-direction: row;
  }

  .banner-head2 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2rem;
    color: #8d5c00;
  }
  .banner {
    font-size: 4rem;
    font-family: sans-serif;
    font-weight: bolder;
    background: -webkit-linear-gradient(#333, #9fa6b2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .demo2 {
    flex-direction: column;
  }

  .arrow-path {
    opacity: 0;
    transform: translate(2rem, -0.5rem) !important;
    animation: fadeGraphTextIn 0.6s forwards;
  }

  .pointer-demo3 {
    top: 0px;
    right: 0px;
  }
  @keyframes point3 {
    0% {
      top: 0px;
      right: 0px;
    }
    16% {
      top: 110px;
      right: -50px;
    }
    20% {
      top: 100px;
      right: -50px;
      transform: rotateX(0deg);
    }
    22% {
      top: 100px;
      right: -50px;
      transform: rotateX(45deg);
    }
    24% {
      top: 100px;
      right: -50px;
      transform: rotateX(0deg);
    }
    100% {
      top: 100px;
      right: -50px;
    }
  }

  .img-size {
    height: 150px;
  }
}

/* Media queries for medium screens (between 577px and 976px) */
@media (min-width: 577px) and (max-width: 976px) {
  .demo2 {
    flex-direction: column;
  }
  .pointer-demo3 {
    top: 0px;
    right: 0px;
  }
  @keyframes point3 {
    0% {
      top: 0px;
      right: 0px;
    }
    16% {
      top: 140px;
      right: -61px;
    }
    20% {
      top: 140px;
      right: -61px;
      transform: rotateX(0deg);
    }
    22% {
      top: 140px;
      right: -61px;
      transform: rotateX(45deg);
    }
    24% {
      top: 140px;
      right: -61px;
      transform: rotateX(0deg);
    }
    100% {
      top: 140px;
      right: -61px;
    }
  }
}
/*  */
/*  */
.pie {
  font-family: "Source Sans Pro", sans-serif;
}
.data-path {
  filter: drop-shadow(0 2px 0px #333);
  cursor: pointer;
}

.data-text {
  transition: transform 0.4s ease-in-out;
  fill: #000;
}
.data-text__value {
  font-size: 2rem;
  transform: translateY(2rem);
  opacity: 0;
  margin-top: -3rem;
}

/* Pie Text Media Query */

.data-text__name {
  font-size: 1.3rem;
  transform: translateY(0rem);
  fill: #3b71ca;
  cursor: pointer;
  opacity: 1;
}
@media (max-width: 768px) {
  .data-text__value {
    font-size: 1.2rem;
  }

  .data-text__name {
    font-size: 0.8rem;
  }
}
.arrow-path {
  opacity: 0;
  transform: translate(3.5rem, -0.7rem);
  animation: fadeGraphTextIn 0.6s forwards;
}

.data-text--show2 {
  animation: fadeGraphTextIn 0.6s forwards;
}

.data-text--show {
  transform: translateY(1rem);
  animation: fadeGraphTextIn 0.6s forwards;
}
.legend-text {
  fill: #000;
}
@keyframes fadeGraphTextIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card-stats {
  margin-top: 30px;
  height: 180px;
  /* width: 285px; */
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px 10px;
}

.card-icon {
  position: relative;
  top: -25px;
  left: 10px;
  height: 70px;
  width: 70px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px #a4a4a4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-stats-title {
  display: flex;
  color: #898686;
  line-height: 25px;
  text-align: end;
  align-items: center;
  position: relative;
  left: 10px;
}

.card-body-stats {
  margin: 0px;
  display: flex;
  align-items: center;
  padding: 5px 0px 0px 5px;
}

/* Slice Effect */
.slice-btn p {
  background: linear-gradient(
    120deg,
    #dbedf9 30%,
    #ffff 38%,
    #ffff 40%,
    #dbedf9 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: slice 2s infinite;
}

.transition-style {
  transform: translate(10px, 0px);
}

@keyframes slice {
  100% {
    background-position: -100% 0;
  }
}

/* Waler Button Animation */
.button {
  color: #333333;
  background-color: #dbedf9;
  position: relative;
  padding: 0.6em 1em;
  font-size: 0.9em;
  transition: border ease-in-out 0.2s;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 5px;
  width: 190px;
  font-weight: bold;
}

.button > p {
  transition: 0.3s;
  margin-right: 2em;
}

.button > i:first-child {
  position: absolute;
  transition: 0.3s;
  left: -100px;
}

.button > i:last-child {
  position: absolute;
  transition: 0.3s;
  right: 20px;
}

.button:hover {
  transition: 0.3s;
  /* border: 1px solid #333333;
  box-shadow: 0px 20px 83px -5px rgba(51,51,51,0.19);  */
}

.button:hover > p {
  transition: 0.3s;
  margin-right: 0;
  margin-left: 3em;
}

.button:hover > i:first-child {
  transition: ease-in-out 0.3s;
  left: 20px;
}

.button:hover > i:last-child {
  transition: ease-in-out 0.3s;
  transform: translateX(500%);
}
