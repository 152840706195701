@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&display=swap");
* {
  font-family: "Quicksand", sans-serif;
  box-sizing: border-box;
}

.apexChartGr {
  height: max-content;
}

.selected4 {
  background-color: #cbdcf7bd;
  padding: 3px 0 3px 60px;
  cursor: pointer;
  color: #0051d1;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
}

.kyc-btn-style2 {
  padding: 3px 0 3px 60px;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 16px;
}
/* .container-primary {
  min-height: 96vh;
} */

.SearchList_Container2 {
  padding: 0px;
  font-size: 0.8rem;
  position: absolute;
  background-color: #fff;
  width: 305px;
  max-height: 70vh;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
}

.bid-verify {
  width: 33rem;
}

@media (max-width: 1023px) {
  .icon-size {
    font-size: 28px;
  }
}
@media (min-width: 1024px) {
  .icon-size {
    font-size: 18px;
  }
  .gap-style {
    gap: 40px;
  }
}

.nav-active {
  position: relative;
  top: -25px;
  transition: 0.4s all;
}

.nav-active span {
  font-size: 21px;
}

.custodian-sideBtn {
  display: flex;
  flex-direction: column;
  position: sticky;
  align-items: center;
}

.btn5 {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1px 1px;
  width: 10rem;
  display: block;
  border-radius: 12px;
  transition: all 0.2s;
  box-shadow: 0px -2px 2px 0px black;
  color: white;
  background-color: #212020cf;
}

.selected-btn {
  background-color: #3b71caad;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1px 1px;
  width: 10rem;
  display: block;
  border-radius: 12px;
  transition: all 0.2s;
  box-shadow: none;
  box-shadow: 0px -2px 2px 0px #3b71ca;
}
.content-height {
  height: 100%;
}

.selected-btn:hover {
  color: white;
  transform: translateY(-3px);
  box-shadow: none;
}

.btn5:hover {
  color: white;
  transform: translateY(-3px);
  box-shadow: none;
}

.btn-animated {
  animation: moveInBottom 5s ease-out;
  animation-fill-mode: backwards;
}

.overflow-style {
  overflow: scroll;
}

::-webkit-scrollbar {
  height: 0.3rem;
  width: 0.4rem;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.card-fontsize {
  font-size: 14px;
  font-weight: bold;
}

.card-fontsize1 {
  font-size: 12px;
}

.card2-style {
  box-shadow: -2px 3px 8px 0px black;
}

.display-style {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.pie-text-style {
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.5rem;
}
.pie-text2-style {
  font-size: 1rem;
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .selected-btn {
    width: 5rem;
    font-size: 0.7rem;
    margin: 0rem 0.5rem;
  }
  .custodian-sideBtn {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 92px;
    top: 0px;
    align-items: baseline;
    justify-content: space-between;
  }
  .custodian-content {
    position: relative;
    left: 0px;
  }

  .btn5 {
    width: 5rem;
    font-size: 0.7rem;
    margin: 0rem 0.5rem;
  }

  .content-height {
    height: 45rem;
  }

  .display-style {
    flex-direction: column;
    align-items: center;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1000px) {
  .selected-btn {
    width: 9rem;
    font-size: 0.8rem;
    margin: 0rem 0.5rem;
  }
  .custodian-sideBtn {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 92px;
    top: 0px;
    align-items: baseline;
    justify-content: space-between;
  }

  .btn5 {
    width: 9rem;
    font-size: 0.8rem;
    margin: 0rem 0.5rem;
  }

  .content-height {
    height: 48rem;
  }

  .display-style {
    flex-direction: column;
    align-items: center;
  }
}

.right {
  flex: 1 1 auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 0 3px 3px 0;
  overflow-x: hidden;
  overflow-y: hidden;
}
.right .title {
  font-size: 25px;
  font-weight: bold;
}
.right .description {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #808080;
}
.right .row {
  display: flex;
  margin: 10px 0 20px;
}
.right .row:last-child {
  margin: 10px 0 0;
}
.right .column {
  flex-direction: column;
}
.right .graph {
  display: inline-block;
  width: 300px;
  height: 210px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 22px -8px #808080;
  padding-top: 1.5rem;
  padding-left: 15px;
  margin-right: 1rem;
  transition: all 0.3s ease-out;
}

.right .graph:hover {
  height: 400px;
  background-color: #fdfbf8;
}
.right .graph .stock {
  margin: 0;
  margin-left: 20px;
  top: 5px;
}
.right .graph + .graph {
  margin-left: 10px;
}
.right .asset-category {
  display: flex;
}
.right .category {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right .asset {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
.right .asset-logo {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 0;
}
.right .asset-logo:before {
  content: "";
  width: 40px;
  height: 40px;
  background-color: #fff;
  transform: rotate(45deg);
  border-radius: 10px;
}
.right .asset-logo i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.right .asset-name {
  color: #808080;
}
.right .sub-title {
  margin-bottom: 10px;
  color: #00008b;
}
.right .half {
  flex: 1;
}
.right .stock {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 5px 0;
  margin: 8px 0;
}

.right .stock-logo {
  position: relative;
  display: flex;
  justify-content: center;
  flex: 0;
  margin-right: 10px;
  padding-top: 1.5rem;
}
.right .stock-logo:before {
  content: "";
  width: 30px;
  height: 30px;
  background-color: #555;
  transform: rotate(45deg);
  border-radius: 7px;
}
.right .stock-logo i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 1.5rem;
}
.right .stock-info {
  margin-left: 5px;
  margin-top: 10px;
  flex: 1;
  padding-top: 1rem;
}
.right .stock-name {
  color: #0f0f0f;
}
.right .stock-fullname {
  color: #808080;
}
.right .stock-value {
  flex: 1;
  font-weight: bold;
  color: #808080;
}
.right .stock-logo.apple:before {
  background: #808080;
}
.right .stock-logo.facebook:before {
  background: #3b5998;
}
.right .stock-logo.amazon:before {
  background: #ff9b24;
}
.right .stock-logo.twitter:before {
  background: #55acee;
}
.right .stock-logo.paypal:before {
  background: #003087;
}
.right .stock-logo.google:before {
  background: #d62d20;
}
.right .stock-logo.paperpillar:before {
  background: linear-gradient(to top right, #7871ff, #6feaff);
}
.right .stock-logo.dandruft:before {
  background: linear-gradient(to top right, #f88781, #f7c682);
}
.stock-detail {
  margin-top: 3rem;
  overflow: hidden;
}
.down-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  padding: 1.8rem;
}

.hidden {
  display: none;
}

/* new section  */

:root {
  --primary-color: #5ab2ff;
  --secondary-color: #dbedf9;
  --text: #212121;
  --white: #fff;
  --black: #000;
  --red: #c20202;
  --light-blue: #def2fd;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.side-content {
  padding: 1rem;
  font-size: 20px;
  font-weight: 700;
  color: var(--text);
  /* margin-top: -22px; */
  p {
    /* margin: 0 !important; */
    font-size: 22px;
    font-weight: 700 !important;
  }
}

.side-sub-title {
  font-size: 14px;
  text-transform: uppercase;
}

/* .custodian-buttons{
  padding-top: .5rem;
} */

.custodian-button {
  --color: var(--primary-color);
  font-family: inherit;
  display: inline-block;
  width: 100%;
  line-height: 1em;
  position: relative;
  overflow: hidden;
  border: none;
  transition: color 0.5s;
  z-index: 1;
  font-size: 13px;
  border-radius: 10px;
  font-weight: 600;
  color: var(--black);
  text-align: left;

  /* padding-left: 3rem; */
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .custodian-button {
   font-size: 13px;
  }}

.custodian-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #d8e5f9;
  height: 300px;
  width: 500px;
  border-radius: 50%;
}

.custodian-button:hover {
  color: #000;
}

.custodian-button:before {
  top: 100%;
  left: 100%;
  transition: all 1s;
}

.custodian-button:hover:before {
  top: -100px;
  left: -100px;
}

.custodian-button:active:before {
  background: #d8e5f9;
  transition: background 0s;
}

.cust-active {
  background-color: #d8e5f9;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.164) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.171) 0px 2px 4px -1px;
}

.faq-sec {
  border: 1px solid var(--secondary-color);
  border-radius: 10px;
  margin-bottom: 5px;
  justify-content: center;
  h2 {
    margin: 0;
  }
}

.accordion-button {
  color: var(--text) !important;
  padding: 5px 0 !important;
  padding-right: 1rem !important;
}

.faq-heading {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--text);
}
.faq-ques {
  font-size: 16px;
  font-weight: 600;
  padding-left: 1rem;
  /* padding-top: 7px; */
  border-radius: 10px 10px 0px 0px;
  margin: 0 !important;
}
.faq-ans {
  font-size: 16px;
  /* padding-top: 5px;
  padding-left: 1rem;
  padding-bottom: 5px; */
  padding: 5px 18px 5px 36px;
  font-weight: 600;
  background-color: var(--light-blue);
  color: var(--text);
  border-radius: 0px 0px 10px 10px;
}
.accordion {
  margin: 0 2rem;
  --mdb-accordion-active-bg: var(--white) !important;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden !important;
}
.faq-ques-box {
  border-radius: 10px 10px 0px 0px !important;
  overflow: hidden !important;
}

/* Custodian Page - Col-WALR */

.apexChart-right {
  padding: 1rem;
  width: 75vw;
  background-color: #ffffff;
  height: 100%;
  padding-left: 5rem;
}

.apexChart-r-top {
  width: 100%;
  height: 320px;
  background-color: #ffffff;
  /* padding: 1rem; */
  padding-bottom: 1rem;
}

.apexChart-r-t-left {
  width: 50%;
  /* background-color: #c93e3e; */
}
.apexChartGraph {
  position: relative;
  float: left;
  right: 2rem;
  width: 80%;
  border-radius: 15px;
  /* background-color: #003087 !important; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.apexChart-r-t-right {
  width: 50%;
  background-color: #ffffff;
}

.apexChart-bottom {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.dataCard {
  height: 90px;
  border-radius: 10px;
}
.dataCard2 {
  height: 100px;
  border-radius: 10px;
}

.smallIcon {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.animationIcon {
  background: linear-gradient(
    120deg,
    #fff 30%,
    #f7f7f7 38%,
    #f7f7f7 40%,
    #fff 48%
  );
  background-color: #000;
  /* margin-top: 10px; */
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 2s infinite;
}

.Icon {
  /* width: 85px; */
  /* height: 85px; */
  float: left;
  border-radius: 5px;
  i {
    font-size: 2rem;
  }
}

.Name {
  /* transform: translate(10px, 0px); */
  border-radius: 5px;
}

@keyframes load89234 {
  100% {
    background-position: -100% 0;
  }
}

/* FAQ Animation */
.faq-animation {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.legend-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  transform: translate(13%, 8%);
}

.legend-container2 {
  display: flex;
  justify-content: center;
  transform: translate(13%, 5%);
}

.legend2 {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.legend-circle2 {
  margin-right: 5px;
}

.legend-text2 {
  font-size: 18px;
}

.data-path2 {
  /* filter: drop-shadow(0 2px 0px #333); */
  cursor: pointer;
}

.data-text2 {
  transition: transform 0.4s ease-in-out;
  fill: #000;
}
.data-text__value2 {
  font-size: 1.5rem;
  transform: translateY(0rem);
  font-weight: 600;
  opacity: 0;
}

.data-text--show2 {
  transform: translateY(-1rem);
  animation: fadeGraphTextIn 0.6s forwards;
}
/* Pie Text Media Query */

.data-text__name2 {
  font-size: 1.3rem;
  transform: translateY(0rem);
  fill: #3b71ca;
  cursor: pointer;
  opacity: 1;
}

.chart-container {
  min-width: 1000px;
  min-height: 500px;
}

.rupee-style {
  transform: translateY(-50px);
  font-size: 30px;
}

.info-style-custodian {
  height: 105px;
  width: 105px;
  animation: move 2s;
}