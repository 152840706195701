.slice input {
    background: linear-gradient(120deg, #DBEAFE 30%, #ffff 38%, #ffff 40%, #DBEAFE 48%);
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: slice 2s infinite;
  }
  
  .transition-style {
    transform: translate(10px, 0px);
  }
  
  @keyframes slice {
    100% {
      background-position: -100% 0;
    }
  }

  /* Drop down Transition */
  .custom-select {
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    font-weight: 600;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Add transition effect */
  }

  .custom-select:focus {
    border-color: #0590F8; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(5, 144, 248, 0.5); /* Add shadow effect on focus */
  }

  .custom-select1 {
    outline: none;
    font-weight: 600;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Add transition effect */
  }

  .custom-select1:focus {
    border-color: #0590F8; /* Change border color on focus */
    box-shadow: 0 0 5px rgba(5, 144, 248, 0.5); /* Add shadow effect on focus */
  }

  .fade-in-out {
    animation: fadeInOut 1s ease-in-out;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  