@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .table-fade-in {
    animation: fadeIn 0.6s ease-in-out;
  }
  