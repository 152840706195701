.coming-container {
  height: 94vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.comingsoon {
  position: absolute;
  top: 25vh;
  font-size: 1rem;
  color: #fff;
  font-family: math;
  font-weight: bolder;
  animation: coming;
  animation-duration: 9s;
  animation-iteration-count: infinite;
}
@keyframes coming {
  0% {
    font-size: 1rem;
  }
  30% {
    font-size: 8rem;
  }
  100% {
    font-size: 8rem;
  }
}

.comingsoon2 {
  position: absolute;
  color: #fff;
  font-family: math;
  bottom: 36vh;
  font-size: 1.6rem;
}
.coming-btn {
  position: absolute;
  bottom: 30vh;
  border-radius: 30px;
  background-image: linear-gradient(90deg, #8d80ec, #6891de, #1db4c0);
  border: 0px;
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .comingsoon {
    top: 30vh;
    font-size: 0.8rem;
  }
  @keyframes coming {
    0% {
      font-size: 0.8rem;
    }
    30% {
      font-size: 3rem;
    }
    100% {
      font-size: 3rem;
    }
  }

  .comingsoon2 {
    bottom: 45vh;
    font-size: 1.5rem;
  }
  .coming-btn {
    bottom: 35vh;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1023px) {
  .comingsoon {
    top: 30vh;
    font-size: 1rem;
  }
  @keyframes coming {
    0% {
      font-size: 1rem;
    }
    30% {
      font-size: 6rem;
    }
    100% {
      font-size: 6rem;
    }
  }
}
