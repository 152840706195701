.logo-img {
  width: 120px;
  height: 120px;
}

.side-img {
  height: 145px;
}

.DT-btn-style {
  padding: 3px 0 3px 30px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.902);
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 16px;
}

.DT-btn-style:hover {
  background-color: #cbdcf7bd;
  cursor: pointer;
  color: #0051d1;
}

/* Close animation */


.circle1 {
  width: 40px;
  height: 40px;
  background: transparent;
  border: 4px solid #e91e63;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.circle1[data-animation="magnify"]:not(.magnify) {
  transition: 100ms ease-in-out;
  &:hover {
    transform: scale(0.9);
  }
}
.circle1.magnify {
  transition: 200ms ease-in-out;
  transform: scale(1.6);
  opacity: 0;
}
.fromMiddle1 {
  &:before,
  &:after {
    z-index: 9999;
    transition-delay: 150ms;
    transition: 400ms ease-in-out;
  }
  span {
    width: 38px;
    height: 38px;
    background-color: #e91e63;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    left: -2px;
    top: -2px;
    z-index: -9999;
    transform: scale(0.3);
    opacity: 0;
    transition: 300ms ease-in-out;
  }
}
.fromMiddle1:hover {
  &:before,
  &:after {
    position: absolute;
    background-color: #fff;
  }
  span {
    transform: scale(1);
    opacity: 1;
  }
}
.circle1:before {
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.circle1:after {
  width: 24px;
  height: 4px;
  background-color: #e91e63;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -2px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
