.bg-style {
  background-size: cover;
  background-position: center;
}

.work-container-subdiv2 {
  height: 40vh;
  width: 70vw;
  border-radius: 2rem;
  margin: 4rem 0rem;
  padding: 1rem;
}
.text-color {
  color: black;
}

.partnership-img {
  min-height: 220px;
}

.work-container-subdiv2:hover {
  transition: all 0.5s;
  transform: scale(1.1);
}

.work-container-subdiv2 p {
  text-align: center;
  color: white;
  margin: 5rem auto;
}
.header-link {
  color: grey;
  cursor: pointer;
}

.header-link:hover {
  color: black;
  text-decoration: underline;
}
.test-card {
  border: 2px solid #3b71ca;
  border-radius: 80px;
  cursor: pointer;
}

.test-card:hover {
  box-shadow: 0 3px 3px 0 rgba(8, 21, 73, 0.308);
}

.cancel-btn {
  position: absolute;
  top: 10vh;
  right: 2vw;
}

/* Test page styling */

.answer {
  overflow-y: scroll;
  max-height: 70vh;
}

.test-footer {
  border-top: 2px solid black;
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0rem;
}

.fact-section {
  cursor: pointer;
  background: #706a65;
  width: 80%;
  height: 250px;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  /* position: absolute;
  top: 54.5rem; */
}

.fact-section:hover {
  transition: all 0.5s;
  top: 41.9rem;
}

.btn2:hover {
  transition: all 0.1s;
  transform: scale(1.1);
}

/* TestCon2 page styling */
.links2-style:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn-fixed {
  position: absolute;
  top: 45%;
}

.btn-fixed2 {
  position: fixed;
  top: 87%;
  right: 12rem;
}

.btn6 {
  width: 13rem;
}

/*   */

.pointer-demo {
  animation-name: point;
  position: relative;
  z-index: 11;
  top: 50px;
  right: -11rem;
  animation-duration: 2.3s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

.pointer-demo2 {
  position: relative;
  z-index: 11;
  top: 50px;
  right: -7rem;
  animation-name: point2;
  animation-duration: 2.8s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

.card-demo1 {
  z-index: 1;
  background: #cbc7c7;
  position: relative;
  animation-name: arrange1;
  animation-duration: 2.3s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

.card-demo2 {
  z-index: 0;
  position: relative;
  animation-name: arrange2;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

.card2-demo1 {
  z-index: 1;
  background: #cbc7c7;
  position: relative;
  animation-name: arrange3;
  animation-duration: 2.8s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

.card2-demo2 {
  z-index: 0;
  position: relative;
  animation-name: arrange4;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  animation-delay: 0.6s;
}

@keyframes point {
  0% {
    top: 50px;
  }
  50% {
    top: 157px;
  }
  100% {
    top: 50px;
  }
}

@keyframes point2 {
  0% {
    right: -7rem;
  }
  40% {
    right: -18rem;
  }
  60% {
    right: -18rem;
  }
  100% {
    right: -7rem;
  }
}

@keyframes arrange1 {
  0% {
    top: 0px;
  }
  50% {
    top: 107px;
  }
  100% {
    top: 0px;
  }
}

@keyframes arrange2 {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: 57px;
  }
  100% {
    bottom: 0px;
  }
}

@keyframes arrange3 {
  0% {
    right: 0rem;
  }
  40% {
    right: -11rem;
  }

  60% {
    right: -11rem;
  }
  100% {
    right: 0rem;
  }
}

@keyframes arrange4 {
  0% {
    bottom: 0px;
  }
  40% {
    bottom: 57px;
  }

  60% {
    bottom: 57px;
  }
  100% {
    bottom: 0px;
  }
}

.test-btn-style {
  background: #c1c1c1;
  color: black;
  border: none;
  border-radius: 1rem;
  width: 60%;
  margin: auto 20%;
  box-shadow: 0px 0px 3px -2px blac;
}

.width-btn-style {
  width: 35%;
}


.btn-shine {
 
  padding: 12px 48px;
  
  color: #414141;
  background: linear-gradient(to right, #2b2b2b 0, #fff 10%, #2b2b2b 20%);
  background-position: 0;
  -webkit-background-clip: text;
  animation: shine 4s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;

  text-decoration: none;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}



/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .fact-section {
    cursor: pointer;
    background: #706a65;
    width: 80%;
    height: 500px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: fixed;
    bottom: -29rem;
    font-size: 0.75rem;
  }

  .pointer-demo2 {
    position: relative;
    z-index: 11;
    top: 50px;
    right: -4rem;
    animation-name: point2;
    animation-duration: 2.8s;
    animation-iteration-count: 1;
    animation-delay: 0.6s;
  }

  @keyframes point2 {
    0% {
      right: -4rem;
    }
    40% {
      right: -12rem;
    }
    60% {
      right: -12rem;
    }
    100% {
      right: -4rem;
    }
  }

  @keyframes arrange3 {
    0% {
      right: 0rem;
    }
    40% {
      right: -8.5rem;
    }

    60% {
      right: -8.5rem;
    }
    100% {
      right: 0rem;
    }
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1100px) {
  .fact-section {
    cursor: pointer;
    background: #706a65;
    width: 80%;
    height: 200px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    position: fixed;
    bottom: -10rem;
    font-size: 0.9rem;
  }
}