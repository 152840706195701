.loader-container {
  position: relative;
  background: #fff;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-container2 {
  height: 100vh;
  position: fixed;
  background: #fff;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 20000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
