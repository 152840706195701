/* From Uiverse.io by vinodjangid07 */ 
.downloadBtn {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
  }
  

  
  .pdficon2 {
    width: 14px;
    height: 4px;
  }
  
  .pdf-tooltip {
    position: absolute;
    right: -105px;
    opacity: 0;
    background-color: rgb(12, 12, 12);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: .2s;
    pointer-events: none;
    letter-spacing: 0.5px;
  }
  
  .pdf-tooltip::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background-color: rgb(12, 12, 12);
    background-size: 1000%;
    background-position: center;
    transform: rotate(45deg);
    left: -5%;
    transition-duration: .3s;
  }
  
  .downloadBtn:hover .pdf-tooltip {
    opacity: 1;
    transition-duration: .3s;
  }
  

  
  .downloadBtn:hover .pdficon2 {
    border-bottom: 2px solid rgb(235, 235, 235);
    border-left: 2px solid rgb(235, 235, 235);
    border-right: 2px solid rgb(235, 235, 235);
  }
  

  
  @keyframes slide-in-top {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  .carousel-indicators2 .carousel-dots button {
    background-color: rgb(59, 59, 59) !important; 
    position: relative;
    top: 10px;
  }
  
  .carousel-indicators2 .carousel-dots .active {
    background-color: #000000; /* Ensure active dot is also black */
  }