#oval {
	stroke-dasharray: 300px;
  stroke-dashoffset: 300px;
	animation: progressAnimation 800ms cubic-bezier(.99,.01,.62,.94) 500ms forwards;

}

#tick {
	transform: scale(0, 0);
	transform-origin: 50% 50%;
	animation: scaleTick  1000ms cubic-bezier(.99,.01,.62,.94) 500ms forwards ;

}

@keyframes scaleTick {
	from {
		transform: scale(0, 0);
	}
	to {
		transform: scale(1, 1);
	}
}

@keyframes progressAnimation {
  from {
    stroke-dashoffset: 300px;
  }
  to {
    stroke-dashoffset: 0;
  }
}






/* :root {
    --color-blue: #0094ff;
    --color-white: #fff;
    --curve: cubic-bezier(0.42, 0, 0.275, 1.155);
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .star {
    position: absolute;
    animation: grow 3s infinite;
    fill: var(--color-blue);
    opacity: 0;
  }
  .star:nth-child(1) {
    width: 6px;
    height: 6px;
    left: 0px;
    top: 12px;
  }
  .star:nth-child(2) {
    width: 6px;
    height: 6px;
    left: 11px;
    top: 26px;
  }
  .star:nth-child(3) {
    width: 8px;
    height: 8px;
    left: 18px;
    top: 38px;
  }
  .star:nth-child(4) {
    width: 6px;
    height: 6px;
    left: 14px;
    top: 14px;
  }
  .star:nth-child(5) {
    width: 6px;
    height: 6px;
    left: 31px;
    top: 34px;
  }
  .star:nth-child(6) {
    width: 6px;
    height: 6px;
    left: 33px;
    top: 18px;
  }
  .star:nth-child(1) {
    animation-delay: 1.5s;
  }
  .star:nth-child(2) {
    animation-delay: 3s;
  }
  .star:nth-child(3) {
    animation-delay: 4.5s;
  }
  .star:nth-child(4) {
    animation-delay: 6s;
  }
  .star:nth-child(5) {
    animation-delay: 7.5s;
  }
  .star:nth-child(6) {
    animation-delay: 9s;
  }
  
  .checkmark {
    position: relative;
    padding: 5px;
    animation: checkmark 5m var(--curve) both;

  }
  .checkmark__check {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate3d(-50%, -50%, 0);
    fill: var(--color-white);
  }
  .checkmark__background {
    fill: var(--color-blue);
    animation: rotate 35s linear both infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes grow {
    0%, 100% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes checkmark {
    0%, 100% {
      opacity: 0;
      transform: scale(0);
    }
    10%, 50%, 90% {
      opacity: 1;
      transform: scale(1);
    }
  }
 */
