.link-btn {
  text-align: center;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.link-btn:hover {
  text-decoration: underline;
}

.contact-input-field {
  background: white;
}

.login-container {
  background: #000;
}

.btn4 {
  border-radius: 12px;
}

/* caveman-page */
.caveman-body {
  font-family: "Concert One", cursive;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  background-color: rgba(88, 88, 88, 0.24);
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*/////////////////// rules */
.text-caveman {
  position: relative;
  left: 50%;
  transform: translate(-50%, -20%);
  color: rgba(19, 36, 44, 0.1);
  font-size: 17em;
  font-weight: bolder;
  text-align: center;
}

.container-caveman {
  position: relative;
  left: 50%;
  transform: translate(-50%, -165%);
  height: 220px;
  width: 450px;
}

/* .container-caveman::after {
  position: relative;
  content: "";
  background-color: rgba(32, 52, 61, 0.1);
  border-radius: 12px;
  bottom: -80px;
  height: 12px;
  left: 40px;
  width: 150px;
  z-index: -1;
} */

.caveman {
  height: 300px;
  position: absolute;
  width: 250px;
}

.caveman:nth-child(1) {
  right: 20px;
}

.caveman:nth-child(2) {
  left: 20px;
  transform: rotateY(180deg);
}

.head {
  position: absolute;
  background-color: #13242c;
  border-radius: 50px;
  height: 140px;
  left: 60px;
  top: 25px;
  width: 65px;
}

.head::after,
.head::before {
  content: "";
  position: absolute;
  background-color: #13242c;
  border-radius: 10px;
  height: 20px;
  width: 7px;
}

.head::after {
  left: 35px;
  top: -8px;
  transform: rotate(20deg);
}

.head::before {
  left: 30px;
  top: -8px;
  transform: rotate(-20deg);
}

.eye {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  background-color: #eab08c;
  border-radius: 50px;
  height: 16px;
  width: 48px;
}

.eye::after,
.eye::before {
  content: "";
  position: absolute;
  background-color: #13242c;
  border-radius: 50%;
  height: 5px;
  width: 5px;
  top: 5px;
}

.eye::after {
  left: 5px;
}

.eye::before {
  right: 9px;
}

.nose {
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
  background-color: #d9766c;
  border-left: 8px solid rgba(19, 36, 44, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  height: 35px;
  width: 15px;
}

.shape {
  position: absolute;
  left: 50%;
  top: 70px;
  transform: translateX(-50%);
  border-radius: 50%;
  height: 140px;
  overflow: hidden;
  width: 140px;
}

.circle {
  position: absolute;
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.circle::after,
.circle::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 20px;
  width: 20px;
}

.circle::after {
  left: 50px;
  top: 10px;
}

.circle::before {
  left: 60px;
  top: 45px;
}

.circle:nth-child(1) {
  left: 2px;
  top: 0px;
}

.circle:nth-child(2) {
  right: 10px;
  top: 0px;
  transform: rotate(90deg);
}

.circle:nth-child(2)::after {
  left: 65px;
  top: 10px;
}

.circle:nth-child(2)::before {
  display: none;
}

.caveman:nth-child(1) .shape {
  background-color: #d13433;
}

.caveman:nth-child(1) .circle {
  background-color: #932422;
}

.caveman:nth-child(1) .circle::after,
.caveman:nth-child(1) .circle::before {
  background-color: #932422;
}

.caveman:nth-child(2) .shape {
  background-color: #932422;
}

.caveman:nth-child(2) .circle {
  background-color: #d13433;
}

.caveman:nth-child(2) .circle::after,
.caveman:nth-child(2) .circle::before {
  background-color: #d13433;
}

.arm-right {
  position: absolute;
  background-color: #eab08c;
  border-left: 8px solid rgba(19, 36, 44, 0.1);
  border-radius: 50px;
  box-sizing: border-box;
  height: 180px;
  left: 135px;
  top: 80px;
  transform-origin: 30px 30px;
  width: 60px;
  z-index: 1;
}

.club {
  position: absolute;
  border-bottom: 110px solid #601513;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  left: -60px;
  top: 120px;
  transform: rotate(70deg);
  width: 40px;
}

.club::after,
.club::before {
  position: absolute;
  content: "";
  background-color: #601513;
  border-radius: 50%;
}

.club::after {
  height: 20px;
  width: 20px;
  top: -10px;
}

.club::before {
  height: 40px;
  width: 40px;
  left: -10px;
  top: 90px;
}

.leg {
  position: absolute;
  border-radius: 10px;
  height: 55px;
  top: 200px;
  width: 10px;
}

.leg::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 10px;
  left: -5px;
  top: 15px;
  width: 10px;
}

.foot {
  position: absolute;
  border-radius: 25px 25px 0 0;
  height: 25px;
  left: -38px;
  top: 30px;
  width: 50px;
}

.foot::after,
.foot::before,
.foot .fingers,
.foot .fingers::after {
  position: absolute;
  background-color: #eab08c;
  border-radius: 50%;
  bottom: 0px;
  height: 15px;
  transform-origin: bottom;
  width: 15px;
}

.foot::after {
  left: -6px;
  content: "";
}

.foot::before {
  left: 8px;
  transform: scale(0.6);
  content: "";
}

.foot .fingers {
  left: 15px;
  transform: scale(0.6);
}

.foot .fingers::after {
  left: 11px;
  content: "";
}

.leg:nth-child(1) {
  background-color: #b2524d;
  left: 95px;
}

.leg:nth-child(1)::after {
  background-color: #b2524d;
}

.leg:nth-child(1) .foot {
  background-color: #b2524d;
}

.leg:nth-child(1) .foot::after {
  background-color: #b2524d;
}

.leg:nth-child(2) {
  background-color: #d9766c;
  left: 115px;
}

.leg:nth-child(2)::after {
  background-color: #d9766c;
}

.leg:nth-child(2) .foot {
  background-color: #d9766c;
}

@keyframes arm-anima {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.caveman:nth-child(1) .arm-right {
  animation: arm-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

.caveman:nth-child(2) .arm-right {
  animation: arm-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
  animation-delay: 0.6s;
}

@keyframes head-anima {
  0% {
    top: 25px;
  }
  42% {
    top: 25px;
  }
  45% {
    top: 50px;
  }
  100% {
    top: 25px;
  }
}

.caveman:nth-child(1) .head {
  animation: head-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
  animation-delay: 0.6s;
}

.caveman:nth-child(2) .head {
  animation: head-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

@keyframes eye-anima {
  0% {
    height: 5px;
  }
  42% {
    height: 5px;
  }
  45% {
    height: 1px;
  }
  100% {
    height: 5px;
  }
}

.caveman:nth-child(1) .eye::after,
.caveman:nth-child(1) .eye::before {
  animation: eye-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
  animation-delay: 0.6s;
}

.caveman:nth-child(2) .eye::after,
.caveman:nth-child(2) .eye::before {
  animation: eye-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
}

.container-caveman::after {
  animation: shadow-anima 1.2s infinite cubic-bezier(0.55, 0.01, 0.16, 1.34);
  animation-delay: 0.1s;
}

@keyframes shadow-anima {
  0% {
    width: 350px;
    left: 80px;
  }
  25% {
    width: 450px;
    left: 80px;
  }
  50% {
    width: 350px;
    left: 80px;
  }
  75% {
    width: 450px;
    left: 0px;
  }
  100% {
    width: 350px;
    left: 80px;
  }
}

/*/////////////////////// credit */
#link {
  bottom: 40px;
  color: #ffffff;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

#link p {
  margin: 0;
  margin-left: 5px;
}
