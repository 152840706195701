:root {
  --admin-navbar-width: -webkit-fill-available;
  --admin-navbar-height: 4rem;
  --admin-sidebar-width: 230px;
  --admin-font-family: Ubuntu, sans-serif;
}

/* navbar */
.admin-navbar {
  z-index: 15;
  width: var(--admin-navbar-width);
  font-weight: 300;
  background: #fff;
  position: fixed;
  height: var(--admin-navbar-height);
}

.admin-navbar-brand-wrapper {
  width: var(--admin-sidebar-width);
  transition: all 0.3s;
  width: var(--admin-navbar-width);
  font-weight: 300;
  background: #fff;
  height: var(--admin-navbar-height);
}

.admin-navbar-brand-wrapper {
  width: var(--admin-sidebar-width);
  transition: all 0.3s;
}

.admin-navbar .admin-navbar-brand-logo {
  width: 120px;
}



.admin-sidebar {
  background-color: #ffffff;
  transition: all 0.3s;
  padding-top: var(--admin-navbar-height);
  z-index: 10;
}

.nav-item {
  padding: 0px 20px;
}
.count-symbol {
  position: absolute;
  top: 5px;
  right: 20px;
  height: 8px;
  background-color: #fe7c96;
  width: 8px;
  border-radius: 50%;
  top: 5px;
  right: 20px;
  height: 8px;
  background-color: #fe7c96;
  width: 8px;
  border-radius: 50%;
  top: 5px;
  right: 20px;
  height: 8px;
  background-color: #fe7c96;
  width: 8px;
  border-radius: 50%;
}

.sidebar-item {
  position: relative;
  padding: 10px 0px;
}

.sidebar-item-collapse {
  position: absolute;
  top: 7px;
  left: 54px;
  background-color: white;
  border-top-right-radius: 25px;
  padding: 10px 25px 10px 2px;
  width: 200px;
  z-index: 1000 !important;
  border: 2px Solid #eee; 
  border-bottom-right-radius: 25px;
  border-left: none;
}

.sidebar-item.active {
  color: #fe7c96;
}

.menu-title {
  font-family: var(--admin-font-family);
  font-size: 0.95rem;
  font-size: 0.95rem;
  line-height: 1;
  transform: rotateY(0deg);
  animation: menu-item;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
}

@keyframes menu-item {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.admin-profile {
  padding: 1.25rem 0rem 2rem 0rem;
}

/* Admin Calender */
.admin-calendar {
  background-color: #fff;
}

.holiday-list-content {
  height: 250px;
  overflow-y: scroll;
  width: --webkit-fill-available;
}

.admin-calendar-head {
  padding: 20px;
  background-image: linear-gradient(180deg, #3b71ca, #81b1ff);
  border-radius: 4px;
}

.employee-list-head {
  background-image: linear-gradient(180deg, #4caf50, #9de1a0);
  border-radius: 4px;
}
.to-do-list-head {
  background-image: linear-gradient(180deg, #ffc107, #ffeb3b);
  border-radius: 4px;
}

.previous-month,
.next-month {
  color: #6b6b6b4d;
}

.sunday {
  color: #dc4c64 !important;
}

.sunday-active {
  color: #dc4c64 !important;
  border-radius: 10px;
  border: 2px solid #dc4c64 !important;
  transition: all 0.2s;
  box-shadow: inset 0.2rem 0.2rem 0.5rem #ffa2b1 !important;
}

.sunday:hover {
  border: 2px solid #dc4c64 !important;
  border-radius: 10px;
  transition: all 0.2s;
}

.active-date {
  border: 2px solid #3b71ca;
  border-radius: 10px;
  color: #3b71ca;
  box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7;
  transition: all 0.2s;
}

.non-active-date {
  border: 2px solid #fff;
}

.non-active-date:hover {
  border: 2px solid #3b71ca;
  border-radius: 10px;
  transition: all 0.2s;
}

.btn-calender-shadow {
  box-shadow: inset 0.2rem 0.2rem 0.5rem #c8d0e7;
}

.admin-calendar-form-input {
  width: 100%;
  border: none;
  border-bottom: 2px solid #3b71ca;
  padding: 0px 1px 4px 1px;
  font-size: 16px;
}

/* card style */

.user-active {
  font-size: 4rem;
}

.card-icon2 {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.card-body-stats2 {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 5px 0px 0px 5px;
}

.user-list-head {
  background-image: linear-gradient(180deg, #dc4c64, #ffa2b1);
  border-radius: 4px;
}

.user-list-content {
  height: 150px;
  overflow-y: scroll;
}

.highlighted {
  height: 20px;
  width: 20px !important;
  position: absolute;
  bottom: 3px;
  left: 3px;
}

.coupon-highlighted {
  height: 20px;
  width: 20px !important;
  position: absolute;
  top: 3px;
  left: 3px;
}

.birthday-highlighted {
  height: 20px;
  width: 20px !important;
  position: absolute;
  top: 3px;
  right: 3px;
}
/* 
.events-highlighted {
  height: 10px;
  width: 10px !important;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 50%;
} */

.events-highlighted {
  display: flex;
  align-items: center;
  height: 10px;
  position: absolute;
  top: 0px;
  left: 3px;
  border-radius: 50%;
}

.event-dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-left: 2px;
}

/* manage document start button */
.start-button {
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
  border: unset;
  color: #212121;
}
.start-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #212121;
  z-index: -1;
  transition: all 250ms;
}

.start-button:hover {
  color: #e8e8e8;
}

.start-button:hover::before {
  width: 100%;
}
.disabled-button {
  border: unset;
  color: #212121;
  z-index: 1;
  background: #e8e8e8;
  position: relative;
  transition: all 250ms;
  overflow: hidden;
}

/*  */

/* styles */
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

body {
  font-family: "Source Sans Pro", sans-serif;
}

.list-items {
  width: 80%;
  position: relative;
  margin: 2rem auto;
}

.list-item {
  position: relative;
  width: 100%;
  display: block;
  float: left;
}

.list-item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  top: 50%;
  left: 0;
  z-index: -1;
  transition: all 200ms ease-out;
}

.list-item:first-of-type .progress-tracker {
  top: 23px;
}

.list-item:first-of-type .lesson-number {
  top: 0;
}

.list-item:last-of-type .progress-tracker {
  height: 35%;
}

.list-item:last-of-type .lesson-number {
  top: 100%;
}

.list-item.completed .lesson-number {
  background-color: #3498db;
  color: white;
}

.list-item:hover:after {
  top: 0%;
  height: 100%;
  background-color: #3498db;
}

.list-item:hover .lesson-details {
  color: white;
}

.lesson-number {
  width: 40px;
  height: 40px;
  position: absolute;
  background-color: white;
  border: 5px solid #3498db;
  border-radius: 50%;
  top: 35%;
  left: -22.5px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1.8rem;
}

.lesson-icon {
  width: 15%;
  float: left;
  padding: 2rem 0rem 2rem 2rem;
}

.lesson-details {
  width: 85%;
  float: left;
  padding: 3rem 2rem;
  transition: all 300ms ease-out;
}

.progress-tracker {
  border-top: 5px solid #3498db;
  position: relative;
  left: 0;
  top: 23px;
  width: 100%;
}

.lesson-icon {
  padding-left: 2rem;
}

/* delete button */

.delete-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: red;
  border: none;
  border-radius: 7px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  background: #e62222;
}

.delete-button,
button span {
  transition: 200ms;
}

.delete-button .text {
  transform: translateX(33px);
  color: white;
  font-weight: 600;
}

.delete-button .icon {
  position: absolute;
  border-left: 1px solid #c41b1b;
  transform: translateX(180px);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button svg {
  width: 15px;
  fill: #eee;
}

.delete-button:hover {
  background: #ff3636;
}

.delete-button:hover .text {
  color: transparent;
}

@media (min-width: 1280px) {
  .delete-button:hover .icon {
    width: 80px;
    border-left: none;
    transform: translateX(60px);
  }
}

.delete-button:focus {
  outline: none;
}

.delete-button:active .icon svg {
  transform: scale(0.8);
}

/* delete button2 */

.delete-button2 {
  width: 240px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #62b3ff;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.delete-button3 {
  width: 240px;
  height: 50px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  background: #2c455c;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.delete-button2,
button span {
  transition: 200ms;
}

.delete-button2 .text {
  transform: translateX(35px);
  color: white;
  font-weight: bold;
}

.delete-button2 .icon {
  position: absolute;
  border-left: 1px solid #0084ff;
  transform: translateX(180px);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button .icon {
  position: absolute;
  border-left: 1px solid #c41b1b;
  overflow: hidden;
  transform: translateX(
    116px
  ); /* Adjusted to be relative to the viewport width */
  height: 10vw; /* Relative height to maintain responsiveness */
  width: 10vw; /* Relative width to maintain responsiveness */
  max-height: 35px; /* Ensure it doesn’t get too large */
  max-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add media queries for finer control on different screen sizes */
@media (max-width: 1024px) {
  .delete-button .icon {
    transform: translateX(85px); /* Adjust position for smaller screens */
    height: 3vw;
    width: 8vw;
  }
  .delete-button:hover .icon {
    width: 80px;
    border-left: none;
    transform: translateX(50px);
  }
}

@media (max-width: 768px) {
  .delete-button .icon {
    transform: translateX(85px); /* Adjust position for smaller screens */
    height: 4vw;
    width: 8vw;
  }
  .delete-button:hover .icon {
    width: 80px;
    border-left: none;
    transform: translateX(50px);
  }
}

@media (max-width: 480px) {
  .delete-button .icon {
    transform: translateX(98px); /* Further adjust for extra small screens */
    height: 30px; /* Fixed smaller size */
    width: 30px;
  }
}

.delete-button2 svg {
  width: 15px;
  fill: #eee;
}

.delete-button2:hover {
  background: #62b3ff;
}

.delete-button2:hover .text {
  color: transparent;
}

.delete-button2:hover .icon {
  width: 150px;
  border-left: none;
  transform: translateX(40px);
}

.delete-button2:focus {
  outline: none;
}

.delete-button2:active .icon svg {
  transform: scale(0.8);
}

/* External Excel Button Animations*/
.button-excel {
  color: #ffffff;
  background-color: #4e83d9;
  position: relative;
  padding: 0.25em 1em;
  font-size: 0.9em;

  transition: border ease-in-out 0.2s;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.button-excel > p {
  transition: 0.3s;
  margin: 0; /* Ensure the text starts centered */
}

.button-excel > i:first-child {
  position: absolute;
  transition: 0.3s;
  left: -100px;
}

.button-excel:hover {
  transition: 0.3s;
}

.button-excel:hover > p {
  transition: 0.3s;
  margin-right: 0;
  margin-left: 2em;
}

.button-excel:hover > i:first-child {
  transition: ease-in-out 0.3s;
  left: 20px;
}

.button-excelsmall {
  color: #ffffff;
  background-color: #4e83d9;
  position: relative;
  padding: 0.25em 1em;
  font-size: 0.9em;

  transition: border ease-in-out 0.2s;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.button-excelsmall > p {
  transition: 0.3s;
  margin: 0; /* Ensure the text starts centered */
}

.button-excelsmall > i:first-child {
  position: absolute;
  transition: 0.3s;
  left: -100px;
}

.button-excelsmall:hover {
  transition: 0.3s;
}

.button-excelsmall:hover > p {
  transition: 0.3s;
  margin-right: 0;
  margin-left: 2em;
}

.button-excelsmall:hover > i:first-child {
  transition: ease-in-out 0.3s;
  left: 10px;
}

/* Animation Shine */

.animationIcon-shine-blue {
  background: linear-gradient(
    120deg,
    #4e83d9 30%,
    #f7f7f7 38%,
    #4e83d9 40%,
    #4e83d9 48%
  );
  background-color: #000;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 3s infinite;
}
.animationIcon-shine-red {
  background: linear-gradient(
    120deg,
    #dc2626 30%,
    #f7f7f7 38%,
    #dc2626 40%,
    #dc2626 48%
  );
  background-color: #000;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: load89234 3s infinite;
}

.opac-0 {
  opacity: 0;
}

.opac-1 {
  opacity: 1;
}
/* Button Animation */
@keyframes effect {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-effect {
  animation: effect 2s linear infinite;
}

.cookieCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.cookieHeading {
  font-weight: 600;
  z-index: 2;
}

.cookieDescription {
  z-index: 2;
}

/* Close button CSS */

.circleadmin {
  width: 25px;
  height: 25px;
  background: transparent;
  border: 3px solid #e91e63;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.circleadmin:after {
  width: 20px;
  height: 3px;
  background-color: #e91e63;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.circleadmin:before {
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -1px;
  width: 20px;
  height: 3px;
  background-color: #e91e63;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.circleadmin[data-animation="showShadow"] {
  -moz-box-shadow: 0px 0px 0px 0px #e91e63 inset;
  -webkit-box-shadow: 0px 0px 0px 0px #e91e63 inset;
  box-shadow: 0px 0px 0px 0px #e91e63 inset;
  -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  border: 0px solid white;
}

.circleadmin[data-animation="showShadow"]:before,
.circleadmin[data-animation="showShadow"]:after {
  -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
  transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}

.circleadmin[data-animation="showShadow"]:not(.showShadow):hover {
  -moz-box-shadow: 0px 0px 0px 3px #e91e63 inset;
  -webkit-box-shadow: 0px 0px 0px 3px #e91e63 inset;
  box-shadow: 0px 0px 0px 3px #e91e63 inset;
}

.circleadmin[data-animation="showShadow"]:not(.showShadow):hover:before {
  -moz-transform: scale(0.7) rotate(45deg);
  -ms-transform: scale(0.7) rotate(45deg);
  -webkit-transform: scale(0.7) rotate(45deg);
  transform: scale(0.7) rotate(45deg);
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.circleadmin[data-animation="showShadow"]:not(.showShadow):hover:after {
  -moz-transform: scale(0.7) rotate(-45deg);
  -ms-transform: scale(0.7) rotate(-45deg);
  -webkit-transform: scale(0.7) rotate(-45deg);
  transform: scale(0.7) rotate(-45deg);
  -moz-transition-delay: 100ms;
  -o-transition-delay: 100ms;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.circleadmin.showShadow {
  -moz-transition: ease-out 300ms;
  -o-transition: ease-out 300ms;
  -webkit-transition: ease-out 300ms;
  transition: ease-out 300ms;
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

/* Marquee effect */
.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  box-sizing: border-box;
}

.scrolling-text::after {
  content: "";
  display: inline-block;
  width: 100%; /* width of empty area for loop effect */
}

.scrolling-text {
  animation: scrollText 4s linear infinite;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
