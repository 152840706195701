

.rotate-ani{
  animation: 8s spinny-token-yayyy  infinite;
}

@keyframes spinny-token-yayyy {
  0% {
    transform: perspective(200px) rotateY(0deg);
  }

  100% {
    transform: perspective(200px) rotateY(360deg);
  }
}


.home-container {
  width: 280px;
  height: 320px;
  position: relative;
}

.home-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: #ffffff;
  filter: blur(40px);
}

.home-card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  overflow: hidden;
}

.home-card .img-content {
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: scale 0.6s, rotate 0.6s, filter 1s;
}

.heading-content{
  
  transition: all 0.4s ease-in-out;
  transition: scale 0.2s, rotate 0.6s, filter 1s;
}

.content-img::after {
  content: "";
  width: 145px;
  height: 145px;
  display: block;
  position: absolute;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  transition: opacity 0.4s ease-out;
}

.content-img {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.home-card .content-home {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: #222222;
  padding: 20px 24px;
  line-height: 1;
  border-radius: 5px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(60px);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.home-card:hover .content-home {
  opacity: 1;
  transform: translateY(0);
}

.home-card:hover .img-content  {
  scale: 2.5;
  rotate: 30deg;
  filter: blur(7px);
}
.home-card:hover .heading-content  {
  scale: 2.5;
  rotate: 30deg;
  filter: blur(7px);
}

.home-card:hover .img-content img{
  fill: transparent;
}
.home-card:hover .heading-content{
  fill: transparent;
}


.arrow {
  overflow: hidden;
  margin-left: 2px;
}
.arrow-i {
  font-size: 50px;
  -webkit-transition-duration: 1s;
  -webkit-transform: translateX(0px);
}

.explore-btn:hover .arrow-i {
  -webkit-transition-duration: 500ms;
  -webkit-transform: translateX(6px);
}




.arrow-move {
  animation: arrow-move 3s infinite ease-in-out;
}

@keyframes arrow-move {
  0% { transform: translateX(0px); }
  50% { transform: translateX(6px); }
  100% { transform: translateX(0px); }
}



:root {
  --color-blue: #0094ff;
  --color-star: #39b959;
  --color-green: #d1ffdb;
  --color-darkgreen: #00c220;
  --color-white: #fff;
  --curve: cubic-bezier(0.42, 0, 0.275, 1.155);
}


.verified-star {
  position: absolute;
  animation: grow 3s infinite;
  fill: var(--color-star);
  opacity: 0;
}
.verified-star:nth-child(1) {
  width: 6px;
  height: 6px;
  left: 18px;
  top: 12px;
}
.verified-star:nth-child(2) {
  width: 12px;
  height: 12px;
  left: 68px;
  top: 74px;
}
.verified-star:nth-child(3) {
  width: 5px;
  height: 5px;
  left: 32px;
  top: 92px;
}
.verified-star:nth-child(4) {
  width: 10px;
  height: 10px;
  left: 72px;
  top: 12px;
}
.verified-star:nth-child(5) {
  width: 7px;
  height: 7px;
  left: 85px;
  top: 52px;
}
.verified-star:nth-child(6) {
  width: 5px;
  height: 5px;
  left: 36px;
  top: 16px;
}
.verified-star:nth-child(1) {
  animation-delay: 1.5s;
}
.verified-star:nth-child(2) {
  animation-delay: 3s;
}
.verified-star:nth-child(3) {
  animation-delay: 4.5s;
}
.verified-star:nth-child(4) {
  animation-delay: 6s;
}
.verified-star:nth-child(5) {
  animation-delay: 7.5s;
}
.verified-star:nth-child(6) {
  animation-delay: 9s;
}

.verified-tick {
  position: relative;
  padding: 20px;
  animation: verified-tick 5m var(--curve) both;
}
.verified-tick__check {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate3d(-50%, -50%, 0);
  fill: var(--color-green);
}
.verified-tick__background {
  fill: var(--color-darkgreen);
  animation: rotate 35s linear both infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes grow {
  0%, 100% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes verified-tick {
  0%, 100% {
    opacity: 0;
    transform: scale(0);
  }
  10%, 50%, 90% {
    opacity: 1;
    transform: scale(1);
  }
}



.section-with-line::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  width: var(--line-width); /* This is dynamically controlled by GSAP */
  height: 3px;
  background-color: black;
  transition: width 1s ease-in;
  
}
