/* Styles for default (large) screens */
.partnerships-menu {
  height: 15rem;
  width: 15rem;
  margin: 4rem 0rem;
  background-size: cover;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0px 0px 3px 0px black;
}

.partnerships-menu:hover {
  transition: all 0.5s;
  transform: scale(1.1);
}

.list {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  margin: 8px;
  background-color: #f8f8f8;
}

.item {
  padding: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  margin: 4px;
}

.partnership-head-text {
  margin: 0px auto;
  line-height: 2.5rem;
  font-family: lato, "helvetica neue", Helvetica, Roboto, Arial, sans-serif;
  color: rgb(45, 45, 45);
  width: 40vw;
  text-transform: uppercase;
}

.partnership-para-text {
  margin: 0px auto;
  font-size: 1.2rem;
  font-family: lato, "helvetica neue", Helvetica, Roboto, Arial, sans-serif;
  color: rgb(101, 101, 101);
  width: 75vw;
}

.partnership-image {
  background-size: cover;
}

/* Circular Animation  */
@keyframes shadowAnimation {
  0% {
    flood-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    flood-color: rgba(0, 208, 255, 0.919);
  }
}
@keyframes shadowAnimation2 {
  0% {
    flood-color: rgba(0, 0, 0, 0.1);
  }
  100% {
    flood-color: rgba(0, 208, 255, 0.919);
  }
}

#animatedFlood {
  animation: shadowAnimation 1s infinite alternate;
}

#animatedFlood2 {
  animation: shadowAnimation2 1s infinite alternate;
}

/* Custom animation */
.dropdown-animate {
  transition: all 1s ease-in-out; /* Correct transition for smooth property change */
  animation: dropdownAnimation 1s ease-in-out; /* Animation settings */
}
@keyframes dropdownAnimation {
  from {
    opacity: 0; /* Start state */
    transform: translateY(-10px); /* Example animation: move down */
  }
  to {
    opacity: 1; /* End state */
    transform: translateY(0); /* Final state */
  }
}


.opac-0 {
  opacity: 0;
}

.opac-1 {
  opacity: 1;
}