.svg-home .mask {
  width: 0;
}
.ping {
  --uib-size: 50px;
  --uib-speed: 1.3s;
  --uib-color: #ffe345;
  position: relative;
  height: var(--uib-size);
  width: var(--uib-size);
}

.ping::before,
.ping::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--uib-color);
  animation: pulse7132 var(--uib-speed) linear infinite;
  transform: scale(0);
  opacity: 0;
}

.ping::after {
  animation-delay: calc(var(--uib-speed) / 2);
}

@keyframes pulse7132 {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* From Uiverse.io by vinodjangid07 */
.downloadBtn {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}

.pdficon2 {
  width: 18px;
  height: 5px;
}

.pdf-tooltip {
  position: absolute;
  right: -105px;
  opacity: 0;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.2s;
  pointer-events: none;
  letter-spacing: 0.5px;
}

.pdf-tooltip::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background-color: rgb(12, 12, 12);
  background-size: 1000%;
  background-position: center;
  transform: rotate(45deg);
  left: -5%;
  transition-duration: 0.3s;
}

.downloadBtn:hover .pdf-tooltip {
  opacity: 1;
  transition-duration: 0.3s;
}

.downloadBtn:hover .pdficon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.btn-conteiner2 {
  display: flex;
  justify-content: center;
}

.btn-scroll2 {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  transition: 2s;
}

.btn-scroll2 .icon-scroll2 {
  transition: 0.5s;
  margin-right: 0px;
  transform: scale(0.6);
}

.icon-scroll2 {
  transition: 1s;
}

.icon-scroll2 {
  width: 20px;
}

/* SVG */
#scroll-icon-one2 {
  transition: 0.8s;
  transform: translateX(-60%);
}

#scroll-icon-two2 {
  transition: 1s;
  transform: translateX(-30%);
}

#scroll-icon-three2 {
  animation: color_anim2 2s infinite 0.4s;
}

#scroll-icon-one2 {
  transform: translateX(0%);
  animation: color_anim2 2s infinite 1.2s;
}

#scroll-icon-two2 {
  transform: translateX(0%);
  animation: color_anim2 2s infinite 0.8s;
}

/* SVG animations */
@keyframes color_anim2 {
  0% {
    fill: white;
  }

  50% {
    fill: #ffbb00;
  }

  100% {
    fill: #ffd95e;
  }
}

/* Button animations */
@-webkit-keyframes btn-scroll {
  0% {
    outline: 0.2em solid #ffbb00;
    outline-offset: 0;
  }
}

@keyframes btn-scroll {
  0% {
    outline: 0.2em solid #ffbb00;
    outline-offset: 0;
  }
}

@keyframes fade-in-out {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fade-in-out {
  animation: fade-in-out 1s ease-in-out;
}
