
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

*{
    
    font-family: "Open Sans", sans-serif;
    box-sizing: border-box;
}

:root{
    --primary-color: #5ab2ff;
    --secondary-color: #DBEDF9;
    --text: #212121;
    --white: #fff;
    --black: #000;
    --red: #c20202;
    --links: #89C8FF;
    --footer-col: #2F2F2F;
}


.btn-conteiner {
    display: flex;
    justify-content: center;
    --color-text: #00c3ff;
    --color-background: #292929;
    --color-outline: #3dd2ffce;
    --color-shadow: #00000080;
  }
  
  .btn-scroll {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    transition: 2s;
  }
  
  
  .btn-scroll .icon-scroll {
    transition: 0.5s;
    margin-right: 0px;
    transform: scale(0.6);
  }
  
  .icon-scroll {
    transition: 1s;
  }
  
  .icon-scroll {
    width: 20px;

  }
  
  /* SVG */
  #scroll-icon-one {
    transition: 0.8s;
    transform: translateX(-60%);
  }
  
  #scroll-icon-two {
    transition: 1s;
    transform: translateX(-30%);
  }
  
  #scroll-icon-three {
    animation: color_anim 2s infinite 0.4s;
  }
  
  #scroll-icon-one {
    transform: translateX(0%);
    animation: color_anim 2s infinite 1.2s;
  }
  
  #scroll-icon-two {
    transform: translateX(0%);
    animation: color_anim 2s infinite 0.8s;
  }
  
  /* SVG animations */
  @keyframes color_anim {
    0% {
      fill: white;
    }
  
    50% {
      fill: var(--color-background);
    }
  
    100% {
      fill: #9ee5fa;
    }
  }
  
  /* Button animations */
  @-webkit-keyframes btn-scroll {
    0% {
      outline: 0.2em solid var(--color-background);
      outline-offset: 0;
    }
  }
  
  @keyframes btn-scroll {
    0% {
      outline: 0.2em solid var(--color-background);
      outline-offset: 0;
    }
  }
  


  /* Slider animation */
  
  @keyframes slide-in-top {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  .carousel-indicators2 .carousel-dots button {
    background-color: rgb(59, 59, 59) !important; 
    position: relative;
    top: 10px;
  }
  
  .carousel-indicators2 .carousel-dots .active {
    background-color: #000000; /* Ensure active dot is also black */
  }