

.my-tooltip-anchor {
  --ui-tooltip-placement: top-end;
  --ui-tooltip-offset: 1.5rem;
}

.tooltip-container {
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
}

.tooltip {
  --background-tooltip: #6e7681; /* Default background color for tooltip */
  position: absolute;
  top: -70px; /* Adjusted top position */
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5em;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  background: var(--background-tooltip);
  color: white; /* Text color */
  border-radius: 5px;
  line-height: 15px;
  width: 210px;
  height: max-content;
  font-size: 13px;
  text-align: center;
}

.tooltip::before {
  position: absolute;
  content: "";
  height: 0.6em;
  width: 0.6em;
  bottom: -0.2em;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  background: var(
    --background-tooltip
  ); /* Use the same background color as the tooltip */
}

.opac-0 {
  opacity: 0;
}

/*  */
/* .circle-progress {
  animation: progress 2s ease-out forwards;
}

@keyframes progress {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
} */



@keyframes fadeIn {
  from {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn1 {
  from {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn2 {
  from {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn3 {
 from {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn4 {
 from {
    opacity: 0.25;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-in-out;
}

.animate-fadeIn1 {
  animation: fadeIn1 1s ease-in-out;
}

.animate-fadeIn2 {
  animation: fadeIn2 1s ease-in-out;
}

.animate-fadeIn3 {
  animation: fadeIn3 1s ease-in-out;
}

.animate-fadeIn4 {
  animation: fadeIn4 1s ease-in-out;
}


.animate-bounce {
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(0);
  }
  100%{
    transform: translateY(-5px);
  }
}

