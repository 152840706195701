.kyc-form-width {
  width: 60vw;
}

.profile-img {
  display: flex;
  flex-direction: row;
  border: 2px-solid black;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

/* .kyc-text2 {
  font-family: SFProDisplay-Regular, sans-serif;
  color: #a0a0a0;
  font-weight: bold;
  font-size: 0.9rem;
} */

.kyc-btn-style {
  padding: 3px 26px 3px 26px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.902);
  font-weight: 400;
  font-size: 0.8rem;
  font-weight: 600;
  /* padding-left: 1rem; */
  line-height: 32px;
}

.userkyc-submit-btn {
  height: 16px;
}

.selected3 {
  padding: 3px 26px 3px 26px;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 32px;
  font-weight: 600;
}

.kyc-heading {
  font-size: 20px;
  line-height: 24px;
  color: #000;
  font-weight: bold;
}

.kyc-sub-heading {
  color: #191919;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.select-border {
  border: none;
}

.kyc-btn-heading {
  cursor: pointer;
  color: #212121;
  font-weight: bold;
  cursor: auto;
  font-size: 1rem;
  line-height: 16px;
}

.checkbox-style {
  margin: 3px 0px;
}

.fatca-box {
  border: 1px solid rgb(0 0 0 / 23%);
  overflow-y: scroll;
  margin-top: 10px;
  height: 140px;
  border-radius: 3px;
}

.font-size2 {
  font-size: 11px;
}

.fatca-check {
  display: flex;
  align-items: start;
  position: sticky;
  bottom: 0px;
  margin: 0px;
  padding: 5px;
  border-top: 1px solid rgb(0 0 0 / 23%);
  background-color: #fff;
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 767px) and (max-width: 1023px) {
}
