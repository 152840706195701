.scroller-style {
  scrollbar-width: thin;
}
* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

.accordion-animation {
  transition: 0.5s all;
  overflow: hidden;
  animation: swipe-down;
  animation-duration: 0.6s;
  animation-iteration-count: 1;
}

@keyframes slide-down {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
