/* HTML and style credits: https://codepen.io/Paolo-Duzioni/pen/WNrJdYd */

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css");

.trello_page {
  overflow: scroll;
}

@media (max-width: 566px) {
  .trello_page {
    width: 75vw;
    overflow: scroll;
  }
}

.width-btn-style2 {
  width: 50%;
}

.left,
.right {
  display: grid;
  grid-gap: 7px;
}

.left {
  grid-template-columns: repeat(3, max-content);
}

.center {
  text-align: center;
}

.right {
  justify-content: end;
  grid-template-columns: repeat(5, max-content);
}

.app-board .board-header {
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 0.5rem;
}
.app-board .board-header .left,
.app-board .board-header .right {
  display: grid;
  grid-gap: 7px;
  align-items: center;
}
.app-board .board-header .left .btn,
.app-board .board-header .right .btn {
  color: var(--app-col);
}
.app-board .board-header .left {
  grid-template-columns: repeat(4, max-content);
}
.app-board .board-header .right {
  justify-content: end;
  grid-template-columns: repeat(2, max-content);
}
.app-board .board-header .title {
  font-weight: 700;
  font-size: 1.25em;
}
.app-board .board-body {
  padding: 0 0.5rem;
  overflow-y: hidden;
  /* height: 100vh; */
}
.app-board .board-body .wrap-lists {
  display: grid;
  grid-auto-columns: 272px;
  grid-auto-flow: column;
  text-align-last: center;
  grid-gap: 7px;
  height: calc(100% - 16px);
  padding-bottom: 0.5rem;
  /* overflow-y: auto; */
}
.app-board .board-body .list1 {
  display: grid;
  grid-auto-rows: max-content;
  grid-gap: 10px;
  padding: 0.5rem;
  width: 100%;
  font-size: 0.875em;
  /* background: rgba(255, 255, 255, 0.5); */
  border-radius: 8px;
}
.app-board .board-body .list1 .list-title {
  margin: 0;
}
.app-board .board-body .list1 .boardContentArea {
  min-height: 15px;
}
.app-board .board-body .list1 .boardContentArea.hovered {
  padding-bottom: 15px;
}
.app-board .board-body .list1 .card {
  padding: 1rem 1.2rem;
  /* background: #fff; */
  border-radius: 8px;
  box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.59);
  margin-bottom: 10px;
  position: relative;
}
.app-board .board-body .list1 .card.hidden {
  display: none;
}
.app-board .board-body .list1 .card.ohhold {
  border: 2px solid #fff;
  transform: rotateZ(-2deg);
}
.app-board .board-body .list1 .card.anotherCardOnTop {
  margin-top: 5px;
}
.app-board .board-body .list1 .card .cardTitle {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 8px;
}
.app-board .board-body .list1 .card .cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.app-board .board-body .list1 .card .cardFooter .label {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 1);
  border-radius: 0.8rem;
}
.app-board .board-body .list1 .card .cardFooter .label.color1 {
  background-color: #ff00001a;
  color: #ff0000;
}
.app-board .board-body .list1 .card .cardFooter .label.color2 {
  background-color: #0020711a;
  color: #002071;
}
.app-board .board-body .list1 .card .cardFooter .label.color3 {
  background-color: #c560001a;
  color: #c56000;
}
.app-board .board-body .list1 .card .cardFooter .collab {
  display: flex;
}
.app-board .board-body .list1 .card .cardFooter .collab .collabPerson {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: -5px;
  border: 2px solid #fff;
  border-radius: 50%;
  /* background-color: #ccc; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-board .board-body .list1 .btn-list {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
}
.app-tint {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.app-tint input[type="color"] {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}
.app-tint label {
  font-size: 1.25em;
  color: var(--app-col);
  cursor: pointer;
}

.todo {
  background-color: rgb(213, 234, 255);
}

.done {
  background-color: rgb(214, 214, 214);
}
/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .app-board .board-body .wrap-lists {
    display: block;
    padding-bottom: 0.5rem;
  }

  .board-col {
    margin-bottom: 0.5rem;
  }

  .width-btn-style2 {
    width: 75%;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1100px) {
  .app-board .board-body .wrap-lists {
    display: grid;
    grid-auto-columns: 200px;
    grid-auto-flow: column;
    text-align-last: center;
    grid-gap: 7px;
    height: calc(100% - 16px);
    padding-bottom: 0.5rem;
  }

  .width-btn-style2{
    width: 70%;
  }

}