:root {
  --progress: #28e0da;
  --success: #1df295;

  --ease: cubic-bezier(0.39, 0, 0.41, 1);
  --out: cubic-bezier(0, 0, 0.23, 1);
  --in: cubic-bezier(0.71, 0.01, 0.91, 0.99);
}

.frame2 {
  display: flex;
  background-color: #ffff;
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  justify-content: center;
}

.loader2 {
  height: 20vw;
  width: 20vw;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: inset 0 0 0 1vw rgba(black, 0.03);
  outline: none;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .loader2 {
    height: 50vw;
    width: 50vw;
  }
}

.loader2 .line2 {
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
  animation: rotate 1.5s var(--ease) forwards infinite;
}

.loader2 .line2:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  border-radius: 50%;
  transition: 1s var(--out), opacity 0s;
  border: 1vw solid transparent;
  transform: rotate(-45deg);
}

.loader2 .line2:first-of-type {
  transform-origin: left center;
  left: 50%;
  right: 0;
}

.loader2 .line2:first-of-type:before {
  left: -100%;
  right: 0;
  border-top-color: var(--progress);
  border-left-color: var(--progress);
  animation: rotate 1.5s var(--ease) infinite;
}

.loader2 .line2:last-of-type {
  right: 50%;
  left: 0;
  transform-origin: right center;
}

.loader2 .line2:last-of-type:before {
  right: -100%;
  left: 0;
  opacity: 0;
  border-bottom-color: var(--progress);
  border-right-color: var(--progress);
  animation: rotate 2s var(--ease) infinite;
}

.complete2 {
  animation: success 1s var(--out) forwards 1.1s;
}

@keyframes rotate {
  to {
    transform: rotate(360deg); /* Adjust the rotation angle as needed */
  }
}

@keyframes success {
  0% {
    box-shadow: inset 0 0 0 1vw rgba(black, 0.03), 0 0 0 0 var(--success),
      0 0 0 0 var(--success);
  }
  100% {
    box-shadow: inset 0 0 0 1vw var(--success), 0 0 0 52vw var(--success),
      0 0 0 52vh var(--success);
  }
}

.loader2 .line2:first-of-type:before {
  animation: none;
  opacity: 1;
  transform: rotate(135deg);
  border-top-color: var(--success);
  border-left-color: var(--success);
}

.loader2 .line2:last-of-type:before {
  opacity: 1;
  transform: rotate(135deg);
  border-bottom-color: var(--success);
  border-right-color: var(--success);
}

.tick2 {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
  width: 34%;
  height: 15%;
}

.tick2:before,
.tick2:after {
  content: "";
  position: absolute;
  background: var(--success);
}

.tick2:before {
  top: 0;
  left: 0;
  width: 1vw;
  bottom: 0;
  transform-origin: top;
  transform: scale3d(1, 0, 1);
  transition: 0.4s var(--ease) 0.6s;
}

.tick2:after {
  right: 0;
  left: 0;
  height: 1vw;
  bottom: 0;
  transform-origin: left;
  transform: scale3d(0, 1, 1);
  transition: 0.8s var(--out) 1s;
}

.complete2 .tick2:before,
.complete2 .tick2:after {
  transform: scale3d(1, 1, 1);
}

.confirm-text {
  z-index: 20;
  color: #000000;
  text-align: center;
  margin-top: 20px;
}

.confirm-btn {
  margin-top: 20px;
  z-index: 20;
}