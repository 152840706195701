.slice div {
  background: linear-gradient(
    120deg,
    #dbeafe 30%,
    #ffff 38%,
    #ffff 40%,
    #dbeafe 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: slice 2s infinite;
}

.transition-style {
  transform: translate(10px, 0px);
}

@keyframes slice {
  100% {
    background-position: -100% 0;
  }
}

.form-walr {
  width: 700px;
}

.slice-btn button {
  background: linear-gradient(
    120deg,
    #0590f8 30%,
    #ffff 38%,
    #ffff 40%,
    #0590f8 48%
  );
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: slice 2s infinite;
}

.transition-style {
  transform: translate(10px, 0px);
}

@keyframes slice {
  100% {
    background-position: -100% 0;
  }
}

.form-walr {
  width: 700px;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInSelect {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-select {
  animation: fadeInSelect 1s ease-in-out;
}

/*  */
/*  */
.welcome-img {
  height: 600px;
}
.slider-loader {
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.slider-words {
  overflow: hidden;
  position: relative;
}
.slider-words::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 20;
}

.slider-word {
  display: block;
  height: 100%;
  animation: spin_4991 40s infinite;
}

@keyframes spin_4991 {
  0% {
    transform: translateY(0%);
  }
  6.25% {
    transform: translateY(-102%);
  }
  12.5% {
    transform: translateY(-200%);
  }
  18.75% {
    transform: translateY(-302%);
  }
  25% {
    transform: translateY(-400%);
  }
  31.25% {
    transform: translateY(-502%);
  }
  37.5% {
    transform: translateY(-600%);
  }
  43.75% {
    transform: translateY(-702%);
  }
  50% {
    transform: translateY(-800%);
  }
  56.25% {
    transform: translateY(-902%);
  }
  62.5% {
    transform: translateY(-1000%);
  }
  68.75% {
    transform: translateY(-1102%);
  }
  75% {
    transform: translateY(-1200%);
  }
  81.25% {
    transform: translateY(-1302%);
  }
  87.5% {
    transform: translateY(-1400%);
  }
  93.75% {
    transform: translateY(-1502%);
  }
  100% {
    transform: translateY(-1600%);
  }
}



/*  */
/*  */
