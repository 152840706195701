.compass-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 40px 20px;
  background: #fff;
  font-family: "Arvo", serif;
}

.compass {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  background-position: center;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #317bac;
  margin: 20px 0;
  display: inline-block;
}
