.linegraph_style {
  width: 100%;
}
.linegraph_style2 {
  width: 600px; 
}

/* Media queries for smaller screens (up to 576px) */
@media (max-width: 576px) {
  .linegraph_style {
    height: 300px;
  }
}

/* Media queries for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 1100px) {
  .linegraph_style {
    height: 400px;
  }
}

/* Media queries for large screens (between 769px and 992px) */
@media (min-width: 1101px) and (max-width: 1440px) {
  .linegraph_style {
    height: 500px;
  }
}

@media (min-width: 1441px) {
  .linegraph_style {
    height: 600px;
  }
}

/* FAQ */

.accordionTitle {
  padding: 1em;
  display: block;
  text-decoration: none;
  transition: background-color 0.5s ease-in-out;
  border-bottom: 1px solid darken(#dbeafe, 5%);
  cursor: pointer;
}
.accordionItem {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}
.accordionItem.is-expanded {
  max-height: 50em;
}
.animateIn {
  animation: accordionIn 0.45s normal ease-in-out both 1;
}
.animateOut {
  animation: accordionOut 0.45s alternate ease-in-out both 1;
}
@keyframes accordionIn {
  0% {
    opacity: 0;
    transform: scale(0.9) rotateX(-60deg);
    transform-origin: 50% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes accordionOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9) rotateX(-60deg);
    transform-origin: 50% 0;
  }
}
